import { l10n } from '@shared/l10n';
import { IConfigMainCarInfoProps } from '@entities/form/car/CarMainInformation/types';

const { validationMessage } = l10n.common;

export const mainInfoCarRules: IConfigMainCarInfoProps = {
    fullName: {
        rules: [
            { required: true, message: validationMessage.notEmpty },
            { max: 100, message: validationMessage.availableCountSymbol(100) },
        ],
    },
    color: { rules: [{ required: true, message: validationMessage.notEmpty }] },
    govNumber: { rules: [{ required: true, message: validationMessage.notEmpty }] },
    madeYear: { rules: [{ required: true, message: validationMessage.notEmpty }] },
    requiredDriveLicence: { rules: [{ required: true, message: validationMessage.notEmpty }] },
};
