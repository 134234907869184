import { CheckableTag } from '@shared/ui/CheckableTag';

import { useDriveLicenceInformation } from '../hooks/useDriveLicenceInformation';

export function OpenDriveCategories() {
    const { driveCategories, isLoading } = useDriveLicenceInformation();

    if (isLoading) {
        return null;
    }

    return (
        <>
            {driveCategories.map(({ id, code }) => (
                <CheckableTag key={id} checked>
                    {code}
                </CheckableTag>
            ))}
        </>
    );
}
