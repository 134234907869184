/**
 *  Утилита для валидации строки на отсутствие символов, кроме чисел
 * */
export function isOnlyNumber<T, Q>(rule: T, value: Q) {
    return Number.isNaN(Number(value)) ? Promise.reject() : Promise.resolve();
}

/**
 *  Утилита для проверки на отрицательные числа
 * */
export function isNegative<T, Q>(rule: T, value: Q) {
    return Number(value) < 0 ? Promise.reject() : Promise.resolve();
}
