import { Button } from '@shared/ui/Button';
import { l10n } from '@shared/l10n';
import { greenBtn } from '@shared/styles';

export function GiveToRent() {
    return (
        <Button type="primary" style={greenBtn}>
            {l10n.cars.actions.giveToRent}
        </Button>
    );
}
