import { Card } from '@shared/ui/Card';
import { ReactNode } from 'react';
import { Collapse } from '@shared/ui/Collapse';
import { useCarFilterPanel } from '@entities/CarFilterPanel/hooks/useCarFilterPanel';

interface IFilterPanel {
    title?: ReactNode;
    isLoading?: boolean;
    width?: number;
}

export function CarFilterPanel({ title, isLoading, width = 300 }: IFilterPanel) {
    const { filters, defaultActiveKeys } = useCarFilterPanel();

    return (
        <Card title={title} loading={isLoading} style={{ width, height: '100%' }}>
            <Collapse items={filters} defaultActiveKey={defaultActiveKeys} />
        </Card>
    );
}
