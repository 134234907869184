import { ICar, ICarFilter } from './interfaces';
import { carFiltersMock } from './mock/carFiltersMock';
import { carsMock } from './mock/carsMock';

export class CarApi {
    // --> API для получения списка всех автомобилей бюро
    static getCarList() {
        // return httpClient<ICar[]>(`${API_PATH}/car/all`, { method: EMethods.GET });
        return new Promise<ICar[]>((resolve) => {
            setTimeout(() => resolve(carsMock), 500);
        });
    }

    // --> API для получения списка фильтров для поиска автомобилей
    static getCarFilters() {
        // return httpClient<ICarFilter>(`${API_PATH}/car/filters`, { method: EMethods.GET });
        return new Promise<ICarFilter>((resolve) => {
            setTimeout(() => resolve(carFiltersMock), 500);
        });
    }
}
