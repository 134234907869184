import { Menu as AntdMenu, Layout as AntdLayout, MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ReactNode, useState } from 'react';
import { MenuItem } from '@shared/types';

interface ISidebar {
    items?: MenuItem[];
    content?: ReactNode;
}

export function Sidebar({ items, content }: ISidebar) {
    const navigate = useNavigate();
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleSelectMenuItem: MenuProps['onClick'] = (e) => {
        navigate(e.key);
    };

    return (
        <AntdLayout style={{ minHeight: '100vh' }}>
            <AntdLayout.Sider collapsible collapsed={isCollapsed} onCollapse={setIsCollapsed}>
                <AntdMenu
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    theme="dark"
                    items={items}
                    onClick={handleSelectMenuItem}
                />
            </AntdLayout.Sider>
            <AntdLayout.Content style={{ height: '100vh', overflow: 'auto' }}>{content}</AntdLayout.Content>
        </AntdLayout>
    );
}
