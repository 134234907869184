import { ReactNode } from 'react';
import { Tag, TagProps, Typography } from 'antd';

interface IStatusTagProps {
    icon?: ReactNode;
    color?: TagProps['color'];
    content?: ReactNode;
}

export function StatusTag({ icon, color = 'success', content }: IStatusTagProps) {
    return (
        <Typography.Text strong style={{ height: '20px' }}>
            <Tag icon={icon} color={color}>
                {content}
            </Tag>
        </Typography.Text>
    );
}
