import { useGate, useUnit } from 'effector-react';

import { $cars, $filterIsLoading, CarsConfigurationContentGate } from '../model/cars';

export function useCarConfigurationContent() {
    useGate(CarsConfigurationContentGate);

    const model = useUnit({
        filterIsLoading: $filterIsLoading,
        cars: $cars,
    });

    return model;
}
