import { StatusTag } from '@shared/ui/StatusTag';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { l10n } from '@shared/l10n';
import { ICar } from '@shared/api/cars/interfaces';

const { actions } = l10n.cars;

export function CarStatus({ car }: { car: ICar }) {
    return car.isRent ? (
        <StatusTag content={actions.inRent} icon={<CloseCircleOutlined />} color="error" />
    ) : (
        <StatusTag content={actions.access} icon={<CheckCircleOutlined />} color="success" />
    );
}
