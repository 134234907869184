/**
 * Возвращает форму слова в зависимости от количества.
 *
 * @param {[string, string]} numerals - Перечисление вариантов слова.
 * */
export function getWordByCount(numerals: [string, string]) {
    return (count: number) => {
        return count <= 4 ? numerals[0] : numerals[1];
    };
}
