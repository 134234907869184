import Icon from '@ant-design/icons';

import { ReactComponent as WheelSvg } from '../assets/wheelIcon.svg';

interface IWheel {
    size?: number;
}
export function Wheel({ size = 20 }: IWheel) {
    return <Icon alt="Wheel Icon" component={WheelSvg} style={{ fontSize: size }} />;
}
