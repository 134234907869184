import { Typography } from 'antd';
import { Steps } from '@shared/ui/Steps';
import { l10n } from '@shared/l10n';
import { FormContainer } from '@shared/styles';

import { useFormData } from '../hooks/useFormData';

import { FormContent } from './blocks/FormContent';

export function RentCarConfigurationContent() {
    const { currentStep, stepsRent } = useFormData();

    return (
        <FormContainer>
            <Typography.Title level={3} style={{ textAlign: 'center' }}>
                {l10n.common.rentContent.title}
            </Typography.Title>
            <Steps size="small" current={currentStep} items={stepsRent} />
            <FormContent />
        </FormContainer>
    );
}
