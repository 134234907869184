import { Col, Row } from 'antd';
import { ReactNode } from 'react';

export function PageLayout({ children }: { children: ReactNode }) {
    return (
        <Row gutter={[0, 0]} style={{ backgroundColor: '#fff' }}>
            <Col span={24}>{children}</Col>
        </Row>
    );
}
