import { ECarDrive, EMotorType, ERoleSide, ETransmission, ICar } from '@shared/api/cars/interfaces';

export const carsMock: ICar[] = [
    {
        id: 1,
        image: 'https://lk.onlinefleet.io/uplimg/1702979249car.jpg',
        brand: 'Toyota Land Cruiser',
        color: 'Белый',
        govNumber: 'AB750B 75RUS',
        madeYear: 2012,
        transmission: ETransmission.Automatic,
        carDrive: ECarDrive.Back,
        roleSide: ERoleSide.Left,
        motor: EMotorType.Diesel,
        enginePower: 196,
        mileage: 152200,
        description:
            'Toyota Land Cruiser (яп. トヨタ ランドクルーザー Toyota Rando-kurūzā) — внедорожник, выпускаемый японской компанией Toyota Motor Corporation с 1951 года. Land Cruiser выпускается дольше всех других автомобилей Toyota[1].\n' +
            '\n' +
            'Производство первого поколения Land Cruiser началось в 1950 году, автомобиль представлял подобие гражданского джипа[2][3]. Land Cruiser производился как кабриолет, хардтоп, универсал и грузовик (пикап). Надёжность и долговечность автомобиля привела к его огромной популярности, особенно в Австралии, на Ближнем Востоке и в Африке (старые серии) благодаря несущей раме и приводу на все четыре колеса[4]. Toyota тестирует Land Cruiser в австралийской глубинке, считая, что это одно из самых сложных условий эксплуатации, благодаря местному рельефу и температуре[5][6][7]. Основными конкурентами считаются автомобили Range Rover, Land Rover Discovery, Jeep Wrangler, Mitsubishi Pajero Sport и Nissan Patrol. В Японии Land Cruiser продаётся только через дилерскую сеть под названием Toyota Store.\n' +
            '\n' +
            'На 2014 год Toyota Land Cruiser J200 доступен на всех рынках, за исключением Канады, Северной Кореи и Южной Кореи. ',
        isRent: false,
    },
    {
        id: 2,
        image: 'https://lk.onlinefleet.io/uplimg/1680280297car.jpg',
        brand: 'Haval Jolion',
        color: 'Серебристый',
        govNumber: 'AB750B 799RUS',
        madeYear: 2022,
        transmission: ETransmission.Mechanic,
        carDrive: ECarDrive.Front,
        roleSide: ERoleSide.Right,
        motor: EMotorType.Hybrid,
        enginePower: 200,
        mileage: 20121,
        description: '',
        isRent: true,
    },
    {
        id: 3,
        image: 'https://lk.onlinefleet.io/uplimg/1479568167car.jpg',
        brand: 'Hyundai Creta',
        color: 'Красный',
        govNumber: 'AB750B 196RUS',
        madeYear: 2018,
        transmission: ETransmission.Automatic,
        carDrive: ECarDrive.Front,
        roleSide: ERoleSide.Left,
        motor: EMotorType.Gas,
        enginePower: 150,
        mileage: 3210,
        description: '',
        isRent: false,
    },
    {
        id: 4,
        image: 'https://lk.onlinefleet.io/uplimg/1508329407car.jpg',
        brand: 'Hyundai Tucson',
        color: 'Голубой',
        govNumber: 'AB750B 175RUS',
        madeYear: 2022,
        transmission: ETransmission.Automatic,
        carDrive: ECarDrive.Back,
        roleSide: ERoleSide.Left,
        motor: EMotorType.Diesel,
        enginePower: 123,
        mileage: 1235,
        description: '',
        isRent: false,
    },
    {
        id: 5,
        image: 'https://lk.onlinefleet.io/uplimg/1463639317car.jpg',
        brand: 'Nissan X-Trail',
        color: 'Серебристый',
        govNumber: 'AB150B 71RUS',
        madeYear: 2023,
        transmission: ETransmission.Automatic,
        carDrive: ECarDrive.Back,
        roleSide: ERoleSide.Left,
        motor: EMotorType.Diesel,
        enginePower: 196,
        mileage: 152200,
        description: '',
        isRent: false,
    },
    {
        id: 6,
        image: 'https://lk.onlinefleet.io/uplimg/1662736965car.jpg',
        brand: 'Geely Coolray Flagship',
        color: 'Белый',
        govNumber: 'AB750B 715RUS',
        madeYear: 2023,
        transmission: ETransmission.Automatic,
        carDrive: ECarDrive.Back,
        roleSide: ERoleSide.Left,
        motor: EMotorType.Diesel,
        enginePower: 196,
        mileage: 152200,
        description: '',
        isRent: false,
    },
    {
        id: 7,
        image: 'https://lk.onlinefleet.io/uplimg/1702979249car.jpg',
        brand: 'Toyota Land Cruiser',
        color: 'Белый',
        govNumber: 'AB750B 75RUS',
        madeYear: 2012,
        transmission: ETransmission.Automatic,
        carDrive: ECarDrive.Back,
        roleSide: ERoleSide.Left,
        motor: EMotorType.Diesel,
        enginePower: 196,
        mileage: 152200,
        description: '',
        isRent: false,
    },
    {
        id: 8,
        image: 'https://lk.onlinefleet.io/uplimg/1680280297car.jpg',
        brand: 'Haval Jolion',
        color: 'Серебристый',
        govNumber: 'AB750B 799RUS',
        madeYear: 2022,
        transmission: ETransmission.Automatic,
        carDrive: ECarDrive.Back,
        roleSide: ERoleSide.Left,
        motor: EMotorType.Diesel,
        enginePower: 196,
        mileage: 152200,
        description: '',
        isRent: false,
    },
    {
        id: 9,
        image: 'https://lk.onlinefleet.io/uplimg/1479568167car.jpg',
        brand: 'Hyundai Creta',
        color: 'Красный',
        govNumber: 'AB750B 196RUS',
        madeYear: 2018,
        transmission: ETransmission.Automatic,
        carDrive: ECarDrive.Back,
        roleSide: ERoleSide.Left,
        motor: EMotorType.Diesel,
        enginePower: 196,
        mileage: 152200,
        description: '',
        isRent: false,
    },
];
