import { Flex } from 'antd';
import { Search } from '@shared/ui/Search';
import { l10n } from '@shared/l10n';
import { UserListItem } from '@entities/UserListItem';
import { List } from '@shared/ui/List';
import { Button } from '@shared/ui/Button';
import { redBtn } from '@shared/styles';
import { SelectUser } from '@features/userActions';

import { useUserContent } from '../../hooks/useUserContent';
import { useFormData } from '../../hooks/useFormData';
import { EStepType } from '../../config/steps';

export function SelectUserContent() {
    const { users, isLoading, nextDisabled } = useUserContent();
    const { handleChangeStep } = useFormData();
    const { actions } = l10n.common.rentContent;
    const { searchClients } = l10n.clients;

    return (
        <>
            <Search placeholder={searchClients.placeholder} enterButton={searchClients.searchBtn} />
            <List
                locale={{ emptyText: searchClients.searchEmpty }}
                dataSource={users}
                renderItem={(user) => <UserListItem user={user} actions={<SelectUser user={user} />} />}
                loading={isLoading}
                style={{ height: '500px', overflow: 'auto' }}
            />
            <Flex justify="flex-end" gap="middle">
                <Button onClick={() => handleChangeStep(EStepType.SelectCar)} style={redBtn}>
                    {actions.prev}
                </Button>
                <Button onClick={() => handleChangeStep(EStepType.Confirmation)} disabled={nextDisabled}>
                    {actions.next}
                </Button>
            </Flex>
        </>
    );
}
