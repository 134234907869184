import { useGate, useUnit } from 'effector-react';

import { $categoryIsLoading, $driveCategories, DriveLicenceGate } from '../model';

export function useCarMainInformation() {
    useGate(DriveLicenceGate);

    const model = useUnit({
        categoryList: $driveCategories,
        isLoading: $categoryIsLoading,
    });

    return model;
}
