import { IConfigMainInfoProps } from '@entities/form/user/MainUserInformation/types';
import { l10n } from '@shared/l10n';

const { validationMessage } = l10n.common;

export const mainInfoRules: IConfigMainInfoProps = {
    surname: { rules: [{ required: true, message: validationMessage.notEmpty }] },
    name: { rules: [{ required: true, message: validationMessage.notEmpty }] },
    patronymic: { rules: [{ required: true, message: validationMessage.notEmpty }] },
    birthday: { rules: [{ required: true, message: validationMessage.notEmpty }] },
};
