import { Button } from '@shared/ui/Button';
import { useNavigate } from 'react-router-dom';
import { getClientCreate } from '@shared/config';
import { l10n } from '@shared/l10n';
import { UserAddOutlined } from '@ant-design/icons';

export function CreateUser() {
    const navigator = useNavigate();
    return (
        <Button onClick={() => navigator(getClientCreate())}>
            <UserAddOutlined />
            {l10n.common.navbar.createUser}
        </Button>
    );
}
