import { CreateCarConfigurationContent } from '@widgets/CreateCarConfigurationContent';
import { AlignCenterContainer } from '@shared/styles';
import { PageLayout } from '@shared/ui/PageLayout';
import { CreateCar } from '@features/navigation/CreateCar';
import { CreateUser } from '@features/navigation/CreateUser';
import { NavigationBar } from '@shared/ui/NavigationBar';

export function CreateCarConfigurationPage() {
    return (
        <PageLayout>
            <NavigationBar>
                <CreateCar />
                <CreateUser />
            </NavigationBar>
            <AlignCenterContainer>
                <CreateCarConfigurationContent />
            </AlignCenterContainer>
        </PageLayout>
    );
}
