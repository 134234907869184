import { Motor, Transmission, Wheel } from '@shared/icons';
import { ICar } from '@shared/api/cars/interfaces';
import { l10n } from '@shared/l10n';

const { title, values } = l10n.cars.specification;

/**
 * Утилита для подготовки технических характеристик автомобля к отображению в карточке
 * */
export function mapSpecification({ motor, transmission, roleSide }: ICar) {
    return [
        { icon: <Wheel />, title: title.wheel, value: values[roleSide] },
        { icon: <Motor />, title: title.motor, value: values[motor] },
        { icon: <Transmission />, title: title.transmission, value: values[transmission] },
    ];
}
