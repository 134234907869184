import Icon from '@ant-design/icons';

import { ReactComponent as MotorSvg } from '../assets/motorIcon.svg';

interface IMotor {
    size?: number;
}
export function Motor({ size = 20 }: IMotor) {
    return <Icon alt="Motor Icon" component={MotorSvg} style={{ fontSize: size }} />;
}
