import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { l10n } from '@shared/l10n';

export enum ELanguage {
    RU = 'ru',
    EN = 'en',
}

interface ILanguageProvider {
    language: string;
    handleLangChange: (lang: string) => void;
}

const defaultLanguage = localStorage.getItem('lang') || l10n.getLanguage();

export const LanguageContext = createContext<ILanguageProvider>({
    language: defaultLanguage,
    handleLangChange: () => {},
});

export function LanguageProvider({ children }: { children: ReactNode }) {
    const [language, setLanguage] = useState('ru');

    const handleLangChange = useCallback((lang: string) => {
        localStorage.setItem('lang', lang);
        l10n.setLanguage(language);
        setLanguage(lang);
    }, []);

    const value = useMemo(() => ({ language, handleLangChange }), [language]);

    return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
}

export const useLanguage = () => useContext(LanguageContext);
