import LocalizedStrings from 'react-localization';

import { ru } from './locales/ru';
import { en } from './locales/en';

const l10nResources = {
    ru,
    en,
};

export const l10n = new LocalizedStrings(l10nResources);
