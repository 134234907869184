import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Sidebar } from '@shared/ui/Sidebar';

import { router } from '../config/config';
import { menuItems } from '../config/getMenuItems';

export function AppRouter() {
    return (
        <BrowserRouter>
            <Sidebar
                items={menuItems}
                content={
                    <Routes>
                        {Object.values(router).map((route) => (
                            <Route key={route.path} element={route.element} path={route.path} />
                        ))}
                    </Routes>
                }
            />
        </BrowserRouter>
    );
}
