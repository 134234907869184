import { createEvent, createStore, restore, sample } from 'effector';
import { SelectCarModel } from '@features/carActions/SelectCar';
import { SelectUserModel } from '@features/userActions';

import { DEFAULT_STEPS_RENT, EStepType, stepDescriptionChange } from '../config/steps';

/** Events */
const dataReset = createEvent();
export const stepChanged = createEvent<EStepType>();

/** Stores */
export const $stepsRent = createStore(DEFAULT_STEPS_RENT).reset(dataReset);
export const $currentStep = restore(stepChanged, EStepType.SelectCar).reset(dataReset);

/** Logic */

// --> Меняет description для шага "Выбор автомобиля"
sample({
    clock: SelectCarModel.$currentBrand,
    source: $stepsRent,
    filter: Boolean,
    fn: (steps, description) => {
        return stepDescriptionChange({ steps, description, id: EStepType.SelectCar });
    },
    target: $stepsRent,
});

// --> После выбора автомобиля переводит пользователя на следующий шаг
sample({
    clock: SelectCarModel.$currentBrand,
    filter: Boolean,
    fn: () => EStepType.SelectUser,
    target: stepChanged,
});

// --> Меняет description для шага "Выбор пользователя"
sample({
    clock: SelectUserModel.$currentUser,
    source: $stepsRent,
    filter: Boolean,
    fn: (steps, description) => {
        return stepDescriptionChange({ steps, description, id: EStepType.SelectUser });
    },
    target: $stepsRent,
});

// --> После выбора клиента переводит пользователя на шаг подтверждение заказа
sample({
    clock: SelectUserModel.$currentUser,
    filter: Boolean,
    fn: () => EStepType.Confirmation,
    target: stepChanged,
});
