import { Search } from '@shared/ui/Search';
import { List } from '@shared/ui/List';
import { CarCard } from '@entities/CarCard';
import { Flex } from 'antd';
import { CarStatus } from '@widgets/CarConfigurationContent/ui/CarStatus';
import { SelectCar } from '@features/carActions/SelectCar';
import { Button } from '@shared/ui/Button';
import { l10n } from '@shared/l10n';

import { useCarContent } from '../../hooks/useCarContent';
import { useFormData } from '../../hooks/useFormData';
import { EStepType } from '../../config/steps';

export function SelectCarContent() {
    const { cars, carIsLoading, nextDisabled } = useCarContent();
    const { handleChangeStep } = useFormData();
    const { filters } = l10n.cars;
    const { actions } = l10n.common.rentContent;

    return (
        <>
            <Search placeholder={filters.searchPlaceholder} enterButton={filters.searchBtn} />
            <List
                dataSource={cars}
                renderItem={(car) => (
                    <List.Item>
                        <CarCard
                            car={car}
                            actions={
                                <Flex style={{ padding: '10px' }} justify="center" gap="10px">
                                    <CarStatus car={car} />
                                    <SelectCar car={car} />
                                </Flex>
                            }
                        />
                    </List.Item>
                )}
                loading={carIsLoading}
                style={{ height: '500px', overflow: 'auto' }}
            />
            <Flex justify="flex-end" gap="middle">
                <Button disabled>{actions.prev}</Button>
                <Button onClick={() => handleChangeStep(EStepType.SelectUser)} disabled={nextDisabled}>
                    {actions.next}
                </Button>
            </Flex>
        </>
    );
}
