import { createEvent, createStore } from 'effector';
import { IUser } from '@shared/api/users/interfaces';

/** Events */
const dataReset = createEvent();
export const userSelected = createEvent<IUser>();

/** Stores */
export const $user = createStore<IUser | null>(null)
    .on(userSelected, (state, newState) => {
        if (state && newState.id === state.id) {
            return null;
        }

        return newState;
    })
    .reset(dataReset);

export const publicApi = {
    $user,
    dataReset,
};
