import { List as AntdList, Skeleton as AntdSkeleton } from 'antd';
import { ListItemMetaProps, ListItemProps } from 'antd/es/list/Item';

interface IListItem extends Omit<ListItemProps, 'title'>, ListItemMetaProps {
    key: number;
    isLoading?: boolean;
    avatar?: ListItemMetaProps['avatar'];
    title?: ListItemMetaProps['title'];
    description?: string;
}
export function ListItem({ isLoading = false, key, children, avatar, title, description }: IListItem) {
    return (
        <AntdList.Item key={key}>
            {isLoading ? (
                <AntdSkeleton loading active title={false} />
            ) : (
                <AntdList.Item.Meta avatar={avatar} title={title} description={description} />
            )}
            {children}
        </AntdList.Item>
    );
}
