import { Button, Flex, Form, Typography } from 'antd';
import { FormContainer, redBtn } from '@shared/styles';
import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import { l10n } from '@shared/l10n';
import { CarMainInformation, CarTechnicalSpecifications } from '@entities/form/car';

import { mainInfoCarRules, technicInfoCarRules } from '../config';

export function CreateCarConfigurationContent() {
    const { addNewCar } = l10n.cars.form;

    const onFinish = (values: any) => {
        console.log(values);
    };

    const onFinishFailed = (values: any) => {
        console.log(values);
    };

    return (
        <FormContainer
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Typography.Title level={4} style={{ textAlign: 'center' }}>
                {addNewCar.title}
            </Typography.Title>
            <CarMainInformation {...mainInfoCarRules} />
            <CarTechnicalSpecifications {...technicInfoCarRules} />
            <Form.Item>
                <Flex justify="flex-end" gap="middle" style={{ marginTop: '30px' }}>
                    <Button type="primary" htmlType="submit" style={redBtn}>
                        {addNewCar.actions.cancel}
                        <DeleteOutlined />
                    </Button>
                    <Button type="primary" htmlType="submit">
                        {addNewCar.actions.addCar}
                        <UserAddOutlined />
                    </Button>
                </Flex>
            </Form.Item>
        </FormContainer>
    );
}
