import { NavigationBar } from '@shared/ui/NavigationBar';
import { CreateCar } from '@features/navigation/CreateCar';
import { CreateUser } from '@features/navigation/CreateUser';
import { PageLayout } from '@shared/ui/PageLayout';
import { MonitoringCarConfigurationContent } from '@widgets/MonitoringCarConfigurationContent';

export function MonitoringCarConfigurationPage() {
    return (
        <PageLayout>
            <NavigationBar>
                <CreateCar />
                <CreateUser />
            </NavigationBar>
            <MonitoringCarConfigurationContent />
        </PageLayout>
    );
}
