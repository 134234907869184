import { Button } from '@shared/ui/Button';
import { EditOutlined } from '@ant-design/icons';

export function EditUser() {
    return (
        <Button type="primary">
            <EditOutlined />
        </Button>
    );
}
