import Icon from '@ant-design/icons';

import { ReactComponent as PassportSvg } from '../assets/passportIcon.svg';

interface IPassport {
    size?: number;
}
export function Passport({ size = 20 }: IPassport) {
    return <Icon alt="Passport Icon" component={PassportSvg} style={{ fontSize: size }} />;
}
