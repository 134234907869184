import { ECarDrive, EMotorType, ERoleSide, ETransmission } from '@shared/api/cars/interfaces';

export const cars = {
    actions: {
        moreInfo: 'Details',
        giveToRent: 'Give to rent',
        access: 'Access',
        inRent: 'In rent',
        select: 'Select',
    },
    filters: {
        title: 'Filters',
        motorType: 'Engine Type',
        roleSide: 'Wheel',
        transmission: 'Transmission',
        carDrive: 'Car drive',
        searchBtn: 'Search',
        searchPlaceholder: 'Enter brand car',
    },
    specification: {
        title: {
            wheel: 'Wheel side',
            motor: 'Motor type',
            transmission: 'Transmission type',
            carDrive: 'Car Drive',
        },
        values: {
            [ECarDrive.Back]: 'Back',
            [ECarDrive.Front]: 'Front',
            [ECarDrive.Full]: '4WD',
            [EMotorType.Gas]: 'Gas',
            [EMotorType.Diesel]: 'Diesel',
            [EMotorType.Hybrid]: 'Hybrid',
            [EMotorType.Petrol]: 'Petrol',
            [EMotorType.Electric]: 'Electric',
            [ERoleSide.Left]: 'Left',
            [ERoleSide.Right]: 'Right',
            [ETransmission.Automatic]: 'Automatic',
            [ETransmission.Mechanic]: 'Mechanic',
        },
    },
    form: {
        addNewCar: {
            title: 'Create new car',
            mainInformation: {
                title: 'Main information',
                fullName: 'Full name',
                color: 'Color',
                govNumber: 'Gov. number',
                madeYear: 'Year of manufacture',
                requiredDriveLicence: 'Require category drive licence',
            },
            technicalSpecification: {
                title: 'Technical specification',
                transmission: {
                    title: 'Transmission',
                    carDriveTitle: 'Car drive',
                    carDriveType: {
                        [ECarDrive.Back]: 'Back',
                        [ECarDrive.Front]: 'Front',
                        [ECarDrive.Full]: '4WD',
                    },
                    transmissionTitle: 'Transmission type',
                    transmissionType: {
                        [ETransmission.Automatic]: 'Automatic',
                        [ETransmission.Mechanic]: 'Mechanic',
                    },
                },
                wheel: {
                    title: 'Wheel',
                    roleSide: {
                        [ERoleSide.Left]: 'Left',
                        [ERoleSide.Right]: 'Right',
                    },
                },
                motor: {
                    title: 'Motor',
                    motorType: {
                        [EMotorType.Gas]: 'Gas',
                        [EMotorType.Diesel]: 'Diesel',
                        [EMotorType.Hybrid]: 'Hybrid',
                        [EMotorType.Petrol]: 'Petrol',
                        [EMotorType.Electric]: 'Electric',
                    },
                },
                enginePower: 'Engine power',
                mileage: 'Car mileage',
                additionalInfo: 'Additional information',
            },
            actions: {
                cancel: 'Cancel',
                addCar: 'Add car',
            },
        },
    },
    pages: {
        monitoring: {
            title: 'Cars for rent',
        },
    },
};
