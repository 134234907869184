import { CarOutlined, ContainerOutlined, FundOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';

import { getCarRentList, getCarRentReservation, getCars, getClients, getSignIn } from '../../../shared/config';
import { l10n } from '../../../shared/l10n';
import { MenuItem } from '../../../shared/types';

export const menuItems: MenuItem[] = [
    { label: l10n.common.pages.clients, key: getClients(), icon: <UserOutlined /> },
    { label: l10n.common.pages.carpark, key: getCars(), icon: <CarOutlined /> },
    { label: l10n.common.pages.rent, key: getCarRentReservation(), icon: <ContainerOutlined /> },
    { label: l10n.common.pages.monitoring, key: getCarRentList(), icon: <FundOutlined /> },
    { label: l10n.common.pages.settings, key: getSignIn(), icon: <SettingOutlined /> },
];
