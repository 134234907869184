import { RentCarConfigurationContent } from '@widgets/RentCarConfigurationContent';
import { AlignCenterContainer } from '@shared/styles';
import { PageLayout } from '@shared/ui/PageLayout';
import { CreateCar } from '@features/navigation/CreateCar';
import { CreateUser } from '@features/navigation/CreateUser';
import { NavigationBar } from '@shared/ui/NavigationBar';

import { useRentCarConfigurationPage } from '../hooks/useRentCarConfigurationPage';

export function RentCarConfigurationPage() {
    useRentCarConfigurationPage();

    return (
        <PageLayout>
            <NavigationBar>
                <CreateCar />
                <CreateUser />
            </NavigationBar>
            <AlignCenterContainer>
                <RentCarConfigurationContent />
            </AlignCenterContainer>
        </PageLayout>
    );
}
