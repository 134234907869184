import { Button } from '@shared/ui/Button';
import { Flex, Typography } from 'antd';
import { l10n } from '@shared/l10n';
import { greenBtn, redBtn } from '@shared/styles';
import { Collapse } from '@shared/ui/Collapse';
import { WarningAlert } from '@shared/ui/alert';

import { useFormData } from '../../hooks/useFormData';
import { EStepType } from '../../config/steps';

import { submitInformation } from './SubmitInformation';

export function SubmitRentContent() {
    const { handleChangeStep } = useFormData();
    const { rentContent } = l10n.common;

    return (
        <>
            <WarningAlert title={rentContent.warningContent.title} message={rentContent.warningContent.message} />
            <Collapse defaultActiveKey={[0, 1]} items={submitInformation} />
            <Typography.Text style={{ textAlign: 'end', color: '#808080' }}>
                {rentContent.warningContent.submitMessage}
            </Typography.Text>
            <Flex justify="flex-end" gap="middle">
                <Button onClick={() => handleChangeStep(EStepType.SelectUser)} style={redBtn}>
                    {rentContent.actions.prev}
                </Button>
                <Button style={greenBtn}>{rentContent.actions.giveToRent}</Button>
            </Flex>
        </>
    );
}
