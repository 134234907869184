import Icon from '@ant-design/icons';

import { ReactComponent as CategorySvg } from '../assets/categoryIcon.svg';

interface ICategory {
    size?: number;
}
export function Category({ size = 20 }: ICategory) {
    return <Icon alt="Category Icon" component={CategorySvg} style={{ fontSize: size }} />;
}
