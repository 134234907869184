import { TDriveLicenceCategory } from '@shared/api/users/interfaces';
import { Flex } from 'antd';
import { DriveCategory } from '@entities/DriveLicence/styled';

export function DriveCategoryList({ categories }: { categories: TDriveLicenceCategory[] }) {
    return (
        <Flex gap="10px">
            {categories.map((category) => (
                <DriveCategory key={category}>{category}</DriveCategory>
            ))}
        </Flex>
    );
}
