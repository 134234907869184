import Icon from '@ant-design/icons';

import { ReactComponent as PoliceSvg } from '../assets/policeIcon.svg';

interface IPolice {
    size?: number;
}
export function Police({ size = 20 }: IPolice) {
    return <Icon alt="Police Icon" component={PoliceSvg} style={{ fontSize: size }} />;
}
