import { EMotorType, ERoleSide, ETransmission, ICarFilter } from '@shared/api/cars/interfaces';

export const carFiltersMock: ICarFilter = {
    motorType: [
        { id: 1, name: EMotorType.Petrol },
        { id: 2, name: EMotorType.Diesel },
        { id: 3, name: EMotorType.Gas },
        { id: 4, name: EMotorType.Electric },
        { id: 5, name: EMotorType.Hybrid },
    ],
    roleSide: [
        { id: 1, name: ERoleSide.Right },
        { id: 2, name: ERoleSide.Left },
    ],
    transmission: [
        { id: 1, name: ETransmission.Automatic },
        { id: 2, name: ETransmission.Mechanic },
    ],
    carDrive: [
        { id: 1, name: 'Передний' },
        { id: 2, name: 'Задний' },
        { id: 3, name: '4WD' },
    ],
};
