import { IDictionaryResponse, TDictionaryModel, TDictionaryPromise } from '@shared/api/dictionary/interfaces';
import { httpClient } from '@shared/libs/httpClient';
import { API_PATH } from '@shared/api/config';
import { EMethods } from '@shared/libs/httpClient/clientCommon';

import { dictionaryCarDrive } from './mock';

export class DictionaryApi {
    static getDictionaryByName(dictionaryName: string) {
        return httpClient<IDictionaryResponse[]>(`${API_PATH}/dictionary/${dictionaryName}`, {
            method: EMethods.GET,
        });
    }

    static async getAllDictionaries(dictionariesName: string[]) {
        const dictionariesRequests: TDictionaryPromise = {} as TDictionaryPromise;

        dictionariesName.forEach((dictionaryName) => {
            Object.assign(dictionariesRequests, {
                [dictionaryName]: this.getDictionaryByName(dictionaryName),
            });
        });

        const dictionaries: TDictionaryModel = {} as TDictionaryModel;

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(dictionariesRequests)) {
            try {
                // eslint-disable-next-line no-await-in-loop
                Object.assign(dictionaries, { [key]: await Promise.resolve(value) });
            } catch (error) {
                console.log(`Error downloading dictionary with key ${key}`);
            }
        }

        return dictionaries;
    }

    static getDriveCategoryDictionary() {
        // return this.getDictionaryByName(EDictionaryName.driveCategory);
        return new Promise<IDictionaryResponse[]>((resolve) => {
            setTimeout(() => resolve(dictionaryCarDrive), 500);
        });
    }
}
