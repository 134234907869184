import { List as AntdList, ListProps } from 'antd';

interface IList<T> extends ListProps<T> {
    dataSource?: T[];
}

export function List<T>({ ...props }: IList<T>) {
    return <AntdList<T> {...props} />;
}

List.Item = AntdList.Item;
