import { Button } from '@shared/ui/Button';
import { DeleteOutlined } from '@ant-design/icons';
import { redBtn } from '@shared/styles';

export function DeleteUser() {
    return (
        <Button type="primary" style={redBtn}>
            <DeleteOutlined />
        </Button>
    );
}
