import styled from 'styled-components';

export const ClientContentContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    position: relative;
`;
