import { createGate } from 'effector-react';
import { createEvent, restore, sample } from 'effector';
import { createEffectApi } from '@shared/effectorHelpers';
import { UsersApi } from '@shared/api/users/usersApi';
import { SelectUserModel } from '@features/userActions';

/** Gates */
export const UsersConfigurationContentGate = createGate('UsersConfigurationContentGate');

/** Events */
const dataReset = createEvent();

/** Fabrics */
export const usersApi = createEffectApi({ effectFn: UsersApi.getAllUsers, resetEvent: dataReset, defaultState: [] });

/** Stores */
export const $users = restore(usersApi.fetchDataFx.doneData, []).reset(dataReset);
export const $isLoading = usersApi.$isLoading;
export const $currentUser = SelectUserModel.$user;

/** Logic */

// --> Получаем список пользователей с сервера
sample({
    clock: UsersConfigurationContentGate.open,
    target: usersApi.dataFetched,
});
