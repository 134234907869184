import styled from 'styled-components';
import { Form } from 'antd';

export const StyledTitle = styled.div`
    text-align: center;
`;

export const UnderlineContainer = styled.span`
    text-decoration: underline;
`;

export const SuccessTitleContainer = styled.h4`
    color: #43a843;
`;

export const ErrorTitleContainer = styled.h4`
    color: #ff0000;
`;

export const AlignCenterContainer = styled.div`
    height: calc(100% - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll;
    padding: 20px;
    box-sizing: border-box;
`;

export const FormContainer = styled(Form)`
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
    min-width: 1100px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow:
        0 0.6088px 2.93329px 0 rgba(0, 0, 0, 0.02),
        0 1.46302px 7.04911px 0 rgba(0, 0, 0, 0.03),
        0 2.75474px 13.27285px 0 rgba(0, 0, 0, 0.04),
        0 4.91399px 23.67649px 0 rgba(0, 0, 0, 0.04),
        0 9.19107px 44.28427px 0 rgba(0, 0, 0, 0.05),
        0 22px 106px 0 rgba(0, 0, 0, 0.07);
    overflow: scroll;
    max-height: 850px;
`;
