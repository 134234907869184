import { Col, Row } from 'antd';
import { ReactNode } from 'react';

export interface IConfigList {
    icon?: ReactNode;
    title?: ReactNode;
    value?: ReactNode;
}

export interface ISpecificationsListProps {
    dataSource: IConfigList[];
    marginTop?: number;
}

export function SpecificationsList({ dataSource, marginTop = 20 }: ISpecificationsListProps) {
    return (
        <Row gutter={[10, 10]} style={{ marginTop }}>
            {dataSource.map((specification) => (
                <Col span={12} key={`${specification.title}`}>
                    {specification.icon} {specification.title}: {specification.value}
                </Col>
            ))}
        </Row>
    );
}
