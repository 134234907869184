import { createEvent, createStore, sample } from 'effector';
import { mapCarFilters } from '@entities/CarFilterPanel/utils/mapCarFilters';
import { ICollapse } from '@shared/ui/Collapse/ui/Collapse';
import { ICarFilter } from '@shared/api/cars/interfaces';

/** Events */
const dataReset = createEvent();
const filtersChanged = createEvent<ICarFilter>();

/** Stores */
export const $filters = createStore<ICollapse['items']>([]).reset(dataReset);

/** Derived store */
export const $defaultActiveKeys = $filters.map((data) => {
    return data ? data.map(({ key }) => (key as string) ?? '') : [];
});

/** Logic */
sample({
    clock: filtersChanged,
    filter: Boolean,
    fn: mapCarFilters,
    target: $filters,
});

export const publicApi = {
    filtersChanged,
    dataReset,
};
