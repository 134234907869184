import { Typography } from 'antd';
import { l10n } from '@shared/l10n';

export function MonitoringCarConfigurationContent() {
    return (
        <>
            <Typography.Title level={3} style={{ textAlign: 'center' }}>
                {l10n.cars.pages.monitoring.title}
            </Typography.Title>
            <div>List car of rental</div>
        </>
    );
}
