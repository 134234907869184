import Icon from '@ant-design/icons';

import { ReactComponent as IssuedSvg } from '../assets/issuedIcon.svg';

interface IIssued {
    size?: number;
}

export function Issued({ size = 20 }: IIssued) {
    return <Icon alt="Home Icon" component={IssuedSvg} style={{ fontSize: size }} />;
}
