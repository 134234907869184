import { createEffectApi } from '@shared/effectorHelpers';
import { DictionaryApi } from '@shared/api/dictionary/dictionaryApi';
import { createStore, sample } from 'effector';
import { IDictionaryResponse } from '@shared/api/dictionary/interfaces';
import { createGate } from 'effector-react';
import { once } from 'patronum';

/** Gates */
export const DriveLicenceGate = createGate('DriveLicenceGate');

/** Fabrics */
export const driveCategoriesApi = createEffectApi({
    effectFn: DictionaryApi.getDriveCategoryDictionary,
    defaultState: [],
});

/** Stores */
export const $driveCategories = createStore<IDictionaryResponse[]>([]).on(
    driveCategoriesApi.fetchDataFx.doneData,
    (_, newState) => {
        return newState;
    }
);

/** Derived stores */
export const $categoryIsLoading = driveCategoriesApi.$isLoading;

/** Logic */
sample({
    clock: once(DriveLicenceGate.open),
    target: driveCategoriesApi.dataFetched,
});
