import { ICar } from '@shared/api/cars/interfaces';
import { ReactNode } from 'react';
import { Flex, Image, Typography } from 'antd';
import { CarCardContainer } from '@entities/CarCard/styled';
import { SpecificationsList } from '@shared/ui/SpecificationsList';
import { mapSpecification } from '@entities/CarCard/lib/mapSpecification';

interface ICarCardProps {
    car: ICar;
    actions?: ReactNode;
    carWidth?: number;
    cardSize?: number;
}

export function CarCard({ car, actions, carWidth = 350, cardSize = 1100 }: ICarCardProps) {
    const fullBrandName = `${car.brand}, ${car.enginePower}`;
    const subtitleInformation = `${car.madeYear}, ${car.color}, ${car.govNumber}`;
    const specificationData = mapSpecification(car);

    return (
        <CarCardContainer>
            <Flex style={{ maxWidth: cardSize, width: '100%' }} gap="middle">
                <Image src={car.image} alt="Car" width={carWidth} />
                <Flex vertical style={{ padding: '10px', maxWidth: '350px' }}>
                    <Typography.Title level={4}>{fullBrandName}</Typography.Title>
                    <Typography>{subtitleInformation}</Typography>
                    <SpecificationsList dataSource={specificationData} />
                </Flex>
                {actions}
            </Flex>
        </CarCardContainer>
    );
}
