import { l10n } from '@shared/l10n';
import { IConfigTechnicCarInfoProps } from '@entities/form/car/CarTechnicalSpecifications/types';
import { isNegative, isOnlyNumber } from '@shared/utils';

const { validationMessage } = l10n.common;

export const technicInfoCarRules: IConfigTechnicCarInfoProps = {
    carDrive: { rules: [{ required: true, message: validationMessage.notEmpty }] },
    roleSide: { rules: [{ required: true, message: validationMessage.notEmpty }] },
    motorType: { rules: [{ required: true, message: validationMessage.notEmpty }] },
    transmissionType: { rules: [{ required: true, message: validationMessage.notEmpty }] },
    enginePower: {
        rules: [
            { required: true, message: validationMessage.notEmpty },
            {
                validator: isOnlyNumber,
                message: validationMessage.onlyNumber,
            },
            {
                validator: isNegative,
                message: validationMessage.notNegative,
            },
        ],
        element: { inputMode: 'numeric' },
    },
    mileage: {
        rules: [
            { required: true, message: validationMessage.notEmpty },
            {
                validator: isOnlyNumber,
                message: validationMessage.onlyNumber,
            },
            {
                validator: isNegative,
                message: validationMessage.notNegative,
            },
        ],
        element: { inputMode: 'numeric' },
    },
};
