import { isOnlyNumber } from '@shared/utils';
import { IConfigPassportInfoProps } from '@entities/form/user/PassportInformation/types';
import { l10n } from '@shared/l10n';

const { validationMessage } = l10n.common;

export const passportRules: IConfigPassportInfoProps = {
    passportSeries: {
        rules: [
            { required: true, message: validationMessage.notEmpty },
            { len: 4, message: validationMessage.countSymbol(4) },
            {
                validator: isOnlyNumber,
                message: validationMessage.onlyNumber,
            },
        ],
        element: { inputMode: 'numeric' },
    },
    passportNumber: {
        rules: [
            { required: true, message: validationMessage.notEmpty },
            { len: 6, message: validationMessage.countSymbol(6) },
            {
                validator: isOnlyNumber,
                message: validationMessage.onlyNumber,
            },
        ],
        element: { inputMode: 'numeric' },
    },
    country: {
        rules: [{ required: true, message: validationMessage.notEmpty }],
    },
    region: {
        rules: [{ required: true, message: validationMessage.notEmpty }],
    },
    city: {
        rules: [{ required: true, message: validationMessage.notEmpty }],
    },
    street: {
        rules: [{ required: true, message: validationMessage.notEmpty }],
    },
    houseNumber: {
        rules: [{ required: true, message: validationMessage.notEmpty }],
    },
    flatNumber: {
        rules: [{ required: true, message: validationMessage.notEmpty }],
    },
};
