import { Flex } from 'antd';
import { l10n } from '@shared/l10n';
import { List } from '@shared/ui/List';
import { Search } from '@shared/ui/Search';
import { UserListItem } from '@entities/UserListItem';
import { UserCard } from '@entities/UserCard';
import { PassportDetails } from '@entities/PassportDetails';
import { DriveLicence } from '@entities/DriveLicence';
import { DeleteUser, EditUser, MoreDetails } from '@features/userActions';

import { useClientsConfigurationContent } from '../hooks/useClientsConfigurationContent';
import { DEFAULT_STYLE_LIST } from '../config/list';
import { ClientContentContainer } from '../styled';

export function ClientsConfigurationContent() {
    const { users, isLoading, selectedUser } = useClientsConfigurationContent();

    return (
        <ClientContentContainer>
            <Search
                placeholder={l10n.clients.searchClients.placeholder}
                enterButton={l10n.clients.searchClients.searchBtn}
                size="middle"
            />
            <Flex gap="middle">
                <List
                    locale={{ emptyText: l10n.clients.searchClients.searchEmpty }}
                    dataSource={users}
                    renderItem={(user) => <UserListItem user={user} actions={<MoreDetails user={user} />} />}
                    style={DEFAULT_STYLE_LIST}
                    loading={isLoading}
                />
                {selectedUser && (
                    <UserCard
                        user={selectedUser}
                        actions={
                            <>
                                <DeleteUser />
                                <EditUser />
                            </>
                        }
                        width={400}
                        height={650}
                    >
                        <PassportDetails {...selectedUser.passport} />
                        <DriveLicence {...selectedUser.driveLicence} />
                    </UserCard>
                )}
            </Flex>
        </ClientContentContainer>
    );
}
