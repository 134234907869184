export const clients = {
    actions: {
        selectUser: 'Выбрать',
        moreDetails: 'Подробнее',
    },
    driveLicence: {
        title: 'Водительское удостоверение',
        details: {
            series: 'Серия',
            inspection: 'ГИБДД',
        },
        status: {
            active: 'Активно до {0}',
            expired: 'Истекло {0}',
        },
    },
    passport: {
        title: 'Паспортные данные',
        series: 'Серия',
        city: 'г.',
        street: 'ул.',
        house: 'д.',
        flat: 'кв.',
    },
    searchClients: {
        placeholder: 'Введите фамилию клиента или номер водительского удостоверения',
        searchBtn: 'Поиск',
        searchEmpty: 'Клиенты не найдены',
    },
    form: {
        addNewUser: {
            title: 'Добавление нового клиента',
            actions: {
                createUser: 'Добавить',
                cancel: 'Отменить',
            },
            mainInformation: {
                surname: 'Фамилия',
                name: 'Имя',
                patronymic: 'Отчество (при наличии)',
                birthday: 'Дата рождения',
            },
            passportInformation: {
                title: 'Паспортные данные',
                passportSeries: 'Серия',
                passportNumber: 'Номер',
                homeAddress: {
                    title: 'Место жительства',
                    country: 'Страна',
                    region: 'Регион',
                    city: 'Населённый пункт',
                    street: 'Улица',
                    houseNumber: 'Дом',
                    flatNumber: 'Квартира',
                },
            },
            driveLicenceInformation: {
                title: 'Водительское удостоверение',
                seriesDrive: 'Серия ВУ',
                numberDrive: 'Номер ВУ',
                dateIssued: 'Выданы',
                dateExpired: 'Истекают',
                inspection: 'Учреждение',
                validPeriod: 'Период действия',
                openedCategory: 'Открытые категории',
            },
            additionalInformation: {
                title: 'Дополнительная информация',
            },
        },
    },
};
