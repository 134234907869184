export enum ETransmission {
    Automatic = 'Automatic',
    Mechanic = 'Mechanic',
}

export enum ECarDrive {
    Front = 'Front',
    Back = 'Back',
    Full = 'Full',
}

export enum ERoleSide {
    Right = 'Right',
    Left = 'Left',
}

export enum EMotorType {
    Petrol = 'Petrol',
    Diesel = 'Diesel',
    Gas = 'Gas',
    Electric = 'Electric',
    Hybrid = 'Hybrid',
}

/**
 * Сущность автомобиля
 * @param {id} id - ID автомобиля
 * @param {string} image - Изображение автомобиля
 * @param {string} brand - Бренд автомобиля
 * @param {string} color - Цвет автомобиля
 * @param {string} govNumber - Государственный номер
 * @param {number} madeYear - Год выпуска автомобиля
 * @param {ETransmission} transmission - Тип коробки передач
 * @param {ECarDrive} carDrive - Привод автомобиля
 * @param {ERoleSide} govNumber - Сторона руля
 * @param {EMotorType} motor - Тип двигателя
 * @param {number} enginePower - Мощность двигателя
 * @param {number} mileage - Пробег автомобиля
 * @param {string} description - Описание автомобиля
 * @param {boolean} isRent - Автомобиль находится в аренде
 *
 * */
export interface ICar {
    id: number;
    image: string;
    brand: string;
    color: string;
    govNumber: string;
    madeYear: number;
    transmission: ETransmission;
    carDrive: ECarDrive;
    roleSide: ERoleSide;
    motor: EMotorType;
    enginePower: number;
    mileage: number;
    description: string;
    isRent: boolean;
}

export interface IFilterItem<T> {
    id: number;
    name: T;
}

export interface ICarFilter {
    motorType: IFilterItem<string>[];
    roleSide: IFilterItem<string>[];
    transmission: IFilterItem<string>[];
    carDrive: IFilterItem<string>[];
}
