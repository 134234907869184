import { useUnit } from 'effector-react';

import { $currentStep, $stepsRent, stepChanged } from '../model';
import { EStepType } from '../config/steps';

export function useFormData() {
    const model = useUnit({
        currentStep: $currentStep,
        stepsRent: $stepsRent,
    });

    const handleChangeStep = (step: EStepType) => {
        stepChanged(step);
    };

    return { ...model, handleChangeStep };
}
