import { ECarDrive, EMotorType, ERoleSide, ETransmission } from '@shared/api/cars/interfaces';

export const cars = {
    actions: {
        moreInfo: 'Подробнее',
        giveToRent: 'Выдать в прокат',
        access: 'Доступно',
        inRent: 'В прокате',
        select: 'Выбрать',
    },
    filters: {
        title: 'Фильтры',
        motorType: 'Тип двигателя',
        roleSide: 'Руль',
        transmission: 'Коробка передач',
        carDrive: 'Привод',
        searchBtn: 'Поиск',
        searchPlaceholder: 'Введите марку автомобиля',
    },
    specification: {
        title: {
            wheel: 'Сторона руля',
            motor: 'Тип двигателя',
            transmission: 'Тип коробки передач',
            carDrive: 'Привод автомобиля',
        },
        values: {
            [ECarDrive.Back]: 'Задний',
            [ECarDrive.Front]: 'Передний',
            [ECarDrive.Full]: '4WD',
            [EMotorType.Gas]: 'Газовый',
            [EMotorType.Diesel]: 'Дизельный',
            [EMotorType.Hybrid]: 'Гибридный',
            [EMotorType.Petrol]: 'Бензиновый',
            [EMotorType.Electric]: 'Электрический',
            [ERoleSide.Left]: 'Левый',
            [ERoleSide.Right]: 'Правый',
            [ETransmission.Automatic]: 'Автомат',
            [ETransmission.Mechanic]: 'Механника',
        },
    },
    form: {
        addNewCar: {
            title: 'Добавление нового автомобиля',
            mainInformation: {
                title: 'Основная информация',
                fullName: 'Полное наименование',
                color: 'Цвет',
                govNumber: 'Гос. номер',
                madeYear: 'Год выпуска',
                requiredDriveLicence: 'Требуемая категория прав',
            },
            technicalSpecification: {
                title: 'Технические характеристики',
                transmission: {
                    title: 'Трансмиссия',
                    carDriveTitle: 'Тип привода',
                    carDriveType: {
                        [ECarDrive.Back]: 'Задний',
                        [ECarDrive.Front]: 'Передний',
                        [ECarDrive.Full]: '4WD',
                    },
                    transmissionTitle: 'Коробка передач',
                    transmissionType: {
                        [ETransmission.Automatic]: 'Автомат',
                        [ETransmission.Mechanic]: 'Механника',
                    },
                },
                wheel: {
                    title: 'Руль',
                    roleSide: {
                        [ERoleSide.Left]: 'Левый',
                        [ERoleSide.Right]: 'Правый',
                    },
                },
                motor: {
                    title: 'Двигатель',
                    motorType: {
                        [EMotorType.Gas]: 'Газовый',
                        [EMotorType.Diesel]: 'Дизельный',
                        [EMotorType.Hybrid]: 'Гибридный',
                        [EMotorType.Petrol]: 'Бензиновый',
                        [EMotorType.Electric]: 'Электрический',
                    },
                },
                enginePower: 'Мощность двигателя',
                mileage: 'Пробег автомобиля',
                additionalInfo: 'Дополнительная информация',
            },
            actions: {
                cancel: 'Отмена',
                addCar: 'Добавить',
            },
        },
    },
    pages: {
        monitoring: {
            title: 'Автомобили в прокате',
        },
    },
};
