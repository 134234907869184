import { Flex, Image, Typography } from 'antd';
import { l10n } from '@shared/l10n';
import { convertDateToString } from '@shared/libs/common';

import { useCarContent } from '../../hooks/useCarContent';
import { useUserContent } from '../../hooks/useUserContent';

const { rentContent: stepsTitle } = l10n.common;

function UserInfo() {
    const { currentUser } = useUserContent();

    if (!currentUser) {
        return null;
    }

    const fullInfo = `${currentUser.surname} ${currentUser.name} ${currentUser.patronymic}, ${convertDateToString(currentUser.bornDate)}`;

    return (
        <Flex gap="middle" align="center">
            <Image src={currentUser.avatar} width={100} />
            <Typography.Text>{fullInfo}</Typography.Text>
        </Flex>
    );
}

function CarInfo() {
    const { currentCar } = useCarContent();

    if (!currentCar) {
        return null;
    }

    const fullInfo = `${currentCar.brand}, ${currentCar.madeYear}, ${currentCar.color.toLowerCase()}, ${currentCar.govNumber}`;

    return (
        <Flex gap="middle" align="center">
            <Image src={currentCar.image} width={150} />
            <Typography.Text>{fullInfo}</Typography.Text>
        </Flex>
    );
}

export const submitInformation = [
    {
        key: 0,
        label: <Typography.Text strong>{stepsTitle.carTitle}</Typography.Text>,
        children: <CarInfo />,
    },
    {
        key: 1,
        label: <Typography.Text strong>{stepsTitle.clientTitle}</Typography.Text>,
        children: <UserInfo />,
    },
];
