import { IUser } from './interfaces';
import { users } from './mock/usersMock';

export class UsersApi {
    // --> API для получения списка всех пользователей системы
    static getAllUsers() {
        // return httpClient<IUser[]>(`${API_PATH}/users/all`, { method: EMethods.GET });
        return new Promise<IUser[]>((resolve) => {
            setTimeout(() => resolve(users), 500);
        });
    }
}
