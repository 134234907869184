import { IUser } from '../interfaces';

export const users: IUser[] = [
    {
        id: 1,
        avatar: 'https://randomuser.me/api/portraits/men/64.jpg',
        surname: 'Попов',
        name: 'Иван',
        patronymic: 'Иванович',
        bornDate: 896191744,
        passport: {
            id: 1,
            series: 5423,
            number: 562365,
            bornAddress: {
                state: 'Россия',
                region: 'Забайкальский край',
                city: 'Чита',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Забайкальский край',
                city: 'Чита',
                flat: 68,
                house: 12,
                street: 'Пушкина',
            },
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 1652365,
            issued: 1652365,
            inspectionNumber: 7868,
            licenceNumber: 5685,
            licenceSeries: 562359,
        },
    },
    {
        id: 2,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 3,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 4,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 5,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 6,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 7,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 8,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 9,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 10,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 11,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 12,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 13,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 14,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 15,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 16,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 17,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 18,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 19,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
    {
        id: 20,
        avatar: 'https://randomuser.me/api/portraits/men/21.jpg',
        name: 'Николай',
        surname: 'Крылов',
        patronymic: 'Александрович',
        bornDate: 1022422144,
        passport: {
            id: 1,
            bornAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
            },
            registrationAddress: {
                state: 'Россия',
                region: 'Пермский край',
                city: 'Пермь',
                flat: 3,
                house: 23,
                street: 'Ляпутина',
            },
            series: 1232,
            number: 565236,
        },
        driveLicence: {
            categories: ['A1', 'B', 'C'],
            expired: 12356545,
            issued: 5652167,
            inspectionNumber: 7456,
            licenceNumber: 2321,
            licenceSeries: 266473,
        },
    },
];
