import Icon from '@ant-design/icons';

import { ReactComponent as TransmissionSvg } from '../assets/transmissionIcon.svg';

interface ITransmission {
    size?: number;
}
export function Transmission({ size = 20 }: ITransmission) {
    return <Icon alt="Transmission Icon" component={TransmissionSvg} style={{ fontSize: size }} />;
}
