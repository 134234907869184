import { Card, Flex, Row } from 'antd';
import { ReactNode } from 'react';
import { IUser } from '@shared/api/users/interfaces';
import { Avatar } from '@shared/ui/Avatar';
import { convertDateToString } from '@shared/libs/common';

interface IUserCard {
    user: IUser;
    actions?: ReactNode;
    children?: ReactNode;
    height?: number;
    width?: number;
}

export function UserCard({ user, actions, children, height, width }: IUserCard) {
    return (
        <Card
            title={
                <Flex gap="20px" vertical style={{ paddingTop: '20px' }}>
                    <Flex justify="flex-end" gap="20px">
                        {actions}
                    </Flex>
                    <Flex gap="middle">
                        <Avatar src={user.avatar} shape="square" size={64} />
                        <Flex vertical>
                            <Row>
                                {user.surname} {user.name} {user.patronymic}
                            </Row>
                            <Row>{convertDateToString(user.bornDate)}</Row>
                        </Flex>
                    </Flex>
                </Flex>
            }
            style={{ maxHeight: height, minWidth: width, position: 'sticky', top: 0 }}
        >
            {children}
        </Card>
    );
}
