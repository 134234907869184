import { ReactNode } from 'react';
import { Row } from 'antd';
import { ListItem } from '@shared/ui/ListItem';
import { IUser } from '@shared/api/users/interfaces';
import { convertDateToString } from '@shared/libs/common';
import { Avatar } from '@shared/ui/Avatar';

interface IUserListItemProps {
    user: IUser;
    actions?: ReactNode;
}

export function UserListItem({ user, actions }: IUserListItemProps) {
    return (
        <ListItem
            key={user.id}
            title={
                <Row>
                    {user.surname} {user.name} {user.patronymic}, ВУ № {user.driveLicence?.licenceNumber}{' '}
                    {user.driveLicence?.licenceSeries}
                </Row>
            }
            description={convertDateToString(user.bornDate)}
            avatar={<Avatar src={user.avatar} />}
        >
            {actions}
        </ListItem>
    );
}
