import Icon from '@ant-design/icons';

import { ReactComponent as StatusSvg } from '../assets/statusIcon.svg';

interface IStatus {
    size?: number;
}
export function Status({ size = 20 }: IStatus) {
    return <Icon alt="Status Icon" component={StatusSvg} style={{ fontSize: size }} />;
}
