import { useUnit } from 'effector-react';

import { $defaultActiveKeys, $filters } from '../model';

export function useCarFilterPanel() {
    const model = useUnit({
        filters: $filters,
        defaultActiveKeys: $defaultActiveKeys,
    });

    return model;
}
