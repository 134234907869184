import { Flex, Input, Typography } from 'antd';
import { l10n } from '@shared/l10n';

export function AdditionalInformation() {
    const title = l10n.clients.form.addNewUser.additionalInformation.title;

    return (
        <Flex vertical>
            <Typography.Title level={5}>{title}</Typography.Title>
            <Input.TextArea showCount placeholder={title} maxLength={300} style={{ height: '100px', resize: 'none' }} />
        </Flex>
    );
}
