import { createEvent } from 'effector';
import { ICar } from '@shared/api/cars/interfaces';
import { restore } from 'effector/compat';

/** Events */
const dataReset = createEvent();
export const carSelected = createEvent<ICar>();

/** Stores */
export const $currentCar = restore(carSelected, null).reset(dataReset);

/** Derived Stores */
const $currentBrand = $currentCar.map((car) => car?.brand ?? '');

export const publicApi = {
    $currentCar,
    $currentBrand,
};
