import { Card as AntdCard, CardProps } from 'antd';
import { CSSProperties, ReactNode } from 'react';

interface ICard extends CardProps {
    title?: string | ReactNode;
    className?: string;
    style?: CSSProperties;
}

export function Card(props: ICard) {
    return <AntdCard {...props} />;
}
