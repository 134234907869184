import Icon from '@ant-design/icons';

import { ReactComponent as WarningSvg } from '../assets/warningIcon.svg';

interface IWarning {
    size?: number;
}
export function Warning({ size = 20 }: IWarning) {
    return <Icon alt="Warning Icon" component={WarningSvg} style={{ fontSize: size }} />;
}
