import { Collapse as AntdCollapse, Skeleton as AntdSkeleton, CollapseProps } from 'antd';

export interface ICollapse extends CollapseProps {
    items?: CollapseProps['items'];
    onChange?: CollapseProps['onChange'];
    defaultActiveKey?: CollapseProps['defaultActiveKey'];
    isLoading?: boolean;
}

export function Collapse(props: ICollapse) {
    return <AntdCollapse {...props}>{!props.items && <AntdSkeleton loading active />}</AntdCollapse>;
}
