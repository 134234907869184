import { Col, Form, Input, Row, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { l10n } from '@shared/l10n';
import { DatePicker } from '@shared/ui/DatePicker';

import { IConfigDriveLicenceProps } from '../types';

import { OpenDriveCategories } from './OpenDriveCategories';

export function DriveLicenceInformation({ series, number, validRange, inspection }: IConfigDriveLicenceProps) {
    const driveLicenceTitle = l10n.clients.form.addNewUser.driveLicenceInformation;

    return (
        <>
            <Typography.Title level={5}>{driveLicenceTitle.title}</Typography.Title>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="series"
                        label={driveLicenceTitle.seriesDrive}
                        required
                        rules={series?.rules}
                        {...series?.item}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder={driveLicenceTitle.seriesDrive}
                            {...series?.element}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="number"
                        label={driveLicenceTitle.numberDrive}
                        required
                        rules={number?.rules}
                        {...number?.item}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder={driveLicenceTitle.numberDrive}
                            {...number?.element}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="validRange"
                        label={driveLicenceTitle.validPeriod}
                        required
                        rules={validRange?.rules}
                        {...validRange?.item}
                    >
                        <DatePicker.RangePicker
                            format="DD.MM.YYYY"
                            placeholder={[driveLicenceTitle.dateIssued, driveLicenceTitle.dateExpired]}
                            style={{ width: '100%' }}
                            {...validRange?.element}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="inspection"
                        label={driveLicenceTitle.inspection}
                        required
                        rules={inspection?.rules}
                        {...inspection?.item}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder={driveLicenceTitle.inspection}
                            {...inspection?.element}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="openCategory"
                        layout="horizontal"
                        label={driveLicenceTitle.openedCategory}
                        required
                    >
                        <OpenDriveCategories />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}
