import { PageLayout } from '@shared/ui/PageLayout';
import { ClientsConfigurationContent } from '@widgets/ClientsConfigurationContent';
import { CreateCar } from '@features/navigation/CreateCar';
import { CreateUser } from '@features/navigation/CreateUser';
import { NavigationBar } from '@shared/ui/NavigationBar';

import { useClientConfigurationPage } from '../hooks/useClientConfigurationPage';

export function ClientsConfigurationPage() {
    useClientConfigurationPage();
    return (
        <PageLayout>
            <NavigationBar>
                <CreateCar />
                <CreateUser />
            </NavigationBar>
            <ClientsConfigurationContent />
        </PageLayout>
    );
}
