import { useUnit } from 'effector-react';

import { $user, userSelected } from '../model';

export function useSelectUser() {
    const model = useUnit({
        user: $user,
    });

    return { ...model, userSelected };
}
