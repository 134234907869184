import React from 'react';

import { AppRouter } from './router';

import './app.css';

function App() {
    return <AppRouter />;
}

export default App;
