import { createEffectApi } from '@shared/effectorHelpers';
import { CarApi } from '@shared/api/cars/carApi';
import { createGate } from 'effector-react';
import { createEvent, restore, sample } from 'effector';
import { SelectCarModel } from '@features/carActions/SelectCar';

/** Gates */
export const RentCarConfigurationContent = createGate('RentCarConfigurationContentGate');

/** Events */
const dataReset = createEvent();

/** Fabrics */
export const carApi = createEffectApi({
    effectFn: CarApi.getCarList,
    resetEvent: dataReset,
    defaultState: [],
});

/** Derived store */
export const $cars = restore(carApi.fetchDataFx.doneData, []).reset(dataReset);
export const $carIsLoading = carApi.$isLoading;
export const $currentCar = SelectCarModel.$currentCar;

/** Logic */
// --> Запрашиваем с сервера фильтры и список автомобилей
sample({
    clock: RentCarConfigurationContent.open,
    target: carApi.dataFetched,
});
