import { Input as AntdInput } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { SearchProps } from 'antd/es/input';

interface ISearch extends SearchProps {
    placeholder?: string;
    enterButton?: string;
    size?: SizeType;
    className?: string;
    onSearch?: SearchProps['onSearch'];
}
export function Search(props: ISearch) {
    return <AntdInput.Search {...props} />;
}
