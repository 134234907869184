import { Col, Form, Input, Row, Upload } from 'antd';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { l10n } from '@shared/l10n';
import { DatePicker } from '@shared/ui/DatePicker';

import { IConfigMainInfoProps } from '../types';

export function MainUserInformation({ surname, name, patronymic, birthday }: IConfigMainInfoProps) {
    const mainInfoTitle = l10n.clients.form.addNewUser.mainInformation;

    return (
        <Row gutter={16}>
            <Col span={4}>
                <Upload name="avatar" listType="picture-card" showUploadList={false}>
                    <button style={{ border: 0, background: 'none' }} type="button">
                        <LoadingOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                    </button>
                </Upload>
            </Col>
            <Col span={20}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name="surname"
                            label={mainInfoTitle.surname}
                            required
                            rules={surname?.rules}
                            {...surname?.item}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder={mainInfoTitle.surname}
                                {...surname?.element}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="name" label={mainInfoTitle.name} required rules={name?.rules} {...name?.item}>
                            <Input prefix={<UserOutlined />} placeholder={mainInfoTitle.name} {...name?.element} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="patronymic"
                            label={mainInfoTitle.patronymic}
                            required
                            rules={patronymic?.rules}
                            {...patronymic?.item}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder={mainInfoTitle.patronymic}
                                {...patronymic?.element}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="birthday"
                            label={mainInfoTitle.birthday}
                            required
                            rules={birthday?.rules}
                            {...birthday?.item}
                        >
                            <DatePicker
                                id="birthday"
                                placeholder={mainInfoTitle.birthday}
                                format="DD.MM.YYYY"
                                {...birthday?.element}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
