export const common = {
    pages: {
        clients: 'Clients',
        carpark: 'Carpark',
        rent: 'Rent',
        monitoring: 'Monitoring',
        settings: 'Settings',
    },
    rentContent: {
        title: 'Car rental',
        carTitle: 'Car',
        clientTitle: 'Client',
        selectCar: 'Choosing a car',
        selectUser: 'Choosing a user',
        submitRent: 'Confirmation',
        actions: {
            next: 'Next',
            prev: 'Previous',
            giveToRent: 'Give to rent',
        },
        warningContent: {
            title: 'Attention',
            message: 'Before renting a car, it is necessary to make sure that the entered data is correct',
            submitMessage: 'By clicking the «Give to Rent» button, you confirm the correctness of the order structure',
        },
    },
    errors: {
        nothingFound: {
            title: 'Nothing found!',
            description: 'Please try changing the request conditions',
        },
    },
    validationMessage: {
        notEmpty: 'The field should not be empty',
        countSymbol: (count: number) => {
            return `The field must contain ${count} symbols`;
        },
        availableCountSymbol: (count: number) => {
            return `The field must contain no more than ${count} characters`;
        },
        onlyNumber: 'Only numbers are allowed',
        notNegative: 'The value must not be less than zero',
    },
    navbar: {
        createUser: 'Create user',
        createCar: 'Create car',
    },
};
