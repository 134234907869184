import { ClientsConfigurationPage } from '@pages/ClientsConfigurationPage';
import { CarConfigurationPage } from '@pages/CarConfigurationPage';
import { RentCarConfigurationPage } from '@pages/RentCarConfigurationPage';
import { NewUserConfigurationPage } from '@pages/NewUserConfigurationPage';
import { CreateCarConfigurationPage } from '@pages/CreateCarConfigurationPage';
import { MonitoringCarConfigurationPage } from '@pages/MonitoringCarConfigurationPage';

import {
    AppRoutes,
    getCarCreate,
    getCarRentList,
    getCarRentReservation,
    getCars,
    getClientCreate,
    getClients,
    getMain,
    getNotFound,
    getProfile,
    getSignIn,
    getSignUp,
    getUserConvention,
} from '../../../shared/config';

export const router = {
    [AppRoutes.MAIN]: {
        path: getMain(),
        element: <div>not found</div>,
    },
    [AppRoutes.SIGN_IN]: {
        path: getSignIn(),
        element: <NewUserConfigurationPage />,
    },
    [AppRoutes.SIGN_UP]: {
        path: getSignUp(),
        element: <div>not found</div>,
    },
    [AppRoutes.PROFILE]: {
        path: getProfile(),
        element: <div>not found</div>,
    },
    [AppRoutes.CLIENTS]: {
        path: getClients(),
        element: <ClientsConfigurationPage />,
    },
    [AppRoutes.CARS]: {
        path: getCars(),
        element: <CarConfigurationPage />,
    },
    [AppRoutes.CLIENT_CREATE]: {
        path: getClientCreate(),
        element: <NewUserConfigurationPage />,
    },
    [AppRoutes.CAR_CREATE]: {
        path: getCarCreate(),
        element: <CreateCarConfigurationPage />,
    },
    [AppRoutes.CAR_RENT_LIST]: {
        path: getCarRentList(),
        element: <MonitoringCarConfigurationPage />,
    },
    [AppRoutes.CAR_RENT]: {
        path: getCarCreate(),
        element: <div>not found</div>,
    },
    [AppRoutes.CAR_RENT_RESERVATION]: {
        path: getCarRentReservation(),
        element: <RentCarConfigurationPage />,
    },
    [AppRoutes.USER_CONVENTION]: {
        path: getUserConvention(),
        element: <div>not found</div>,
    },
    [AppRoutes.NOT_FOUND]: {
        path: getNotFound(),
        element: <div>not found</div>,
    },
};
