import { Card } from '@shared/ui/Card';
import { l10n } from '@shared/l10n';
import { Flex } from 'antd';
import { Category, Issued, Police, Status } from '@shared/icons';
import { IDriveLicence } from '@shared/api/users/interfaces';
import { convertDateToString, isDateExpired } from '@shared/libs/common';
import { ErrorTitleContainer, StyledTitle, SuccessTitleContainer, UnderlineContainer } from '@shared/styles';
import { getL10nWithArgs } from '@shared/l10n/lib';

import { DriveCategoryList } from './DriveCategoryList';

export function DriveLicence({
    licenceNumber,
    licenceSeries,
    inspectionNumber,
    categories,
    issued,
    expired,
}: IDriveLicence) {
    const statusMessage = isDateExpired(expired) ? (
        <ErrorTitleContainer>
            {getL10nWithArgs(l10n.clients.driveLicence.status.expired, convertDateToString(expired))}
        </ErrorTitleContainer>
    ) : (
        <SuccessTitleContainer>
            {getL10nWithArgs(l10n.clients.driveLicence.status.active, convertDateToString(expired))}
        </SuccessTitleContainer>
    );

    return (
        <Card title={<StyledTitle>{l10n.clients.driveLicence.title}</StyledTitle>}>
            <Flex vertical gap="middle">
                <Flex gap="middle">
                    <Status />
                    {statusMessage}
                </Flex>
                <Flex gap="middle">
                    <Issued />
                    <Flex gap="5px">
                        {l10n.clients.driveLicence.details.series}{' '}
                        <UnderlineContainer>{licenceNumber}</UnderlineContainer> №{' '}
                        <UnderlineContainer>{licenceSeries}</UnderlineContainer>
                    </Flex>
                </Flex>
                <Flex gap="middle">
                    <Police />
                    {l10n.clients.driveLicence.details.inspection} {inspectionNumber}, {convertDateToString(issued)}
                </Flex>
                <Flex gap="middle">
                    <Category />
                    <DriveCategoryList categories={categories} />
                </Flex>
            </Flex>
        </Card>
    );
}
