import { Select } from '@shared/ui/Select';
import { ELanguage, useLanguage } from '@shared/providers/language/LanguageProvider';

const selectOptions = [
    { value: ELanguage.RU, label: 'Русский' },
    { value: ELanguage.EN, label: 'English' },
];

export function LanguageSwitcher() {
    const { language, handleLangChange } = useLanguage();

    return <Select defaultValue={language} options={selectOptions} onChange={handleLangChange} />;
}
