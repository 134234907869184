import { useGate, useUnit } from 'effector-react';

import { $cars, $carIsLoading, RentCarConfigurationContent, $currentCar } from '../model/cars';

export function useCarContent() {
    useGate(RentCarConfigurationContent);

    const model = useUnit({
        cars: $cars,
        carIsLoading: $carIsLoading,
        currentCar: $currentCar,
    });

    const nextDisabled = !model.currentCar;

    return { ...model, nextDisabled };
}
