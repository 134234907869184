export enum AppRoutes {
    MAIN = 'main',
    PROFILE = 'profile',
    CLIENTS = 'clients',
    CARS = 'cars',
    CLIENT_CREATE = 'client-create',
    CAR_CREATE = 'car-create',
    CAR_RENT = 'car-rent',
    CAR_RENT_LIST = 'car-rent/list',
    CAR_RENT_RESERVATION = 'car-rent/reservation',
    SIGN_IN = 'sign-in',
    SIGN_UP = 'sign-up',
    NOT_FOUND = '*',
    USER_CONVENTION = 'convention',
}

export const getMain = () => '/';
export const getProfile = () => `/${AppRoutes.PROFILE}`;
export const getClients = () => `/${AppRoutes.CLIENTS}`;
export const getCars = () => `/${AppRoutes.CARS}`;
export const getClientCreate = () => `/${AppRoutes.CLIENT_CREATE}`;
export const getCarCreate = () => `/${AppRoutes.CAR_CREATE}`;
export const getSignIn = () => `/${AppRoutes.SIGN_IN}`;
export const getSignUp = () => `/${AppRoutes.SIGN_UP}`;
export const getCarRentList = () => `/${AppRoutes.CAR_RENT_LIST}`;
export const getCarRentReservation = () => `/${AppRoutes.CAR_RENT_RESERVATION}`;
export const getCarRent = () => `/${AppRoutes.CAR_RENT}`;
export const getUserConvention = () => `/${AppRoutes.USER_CONVENTION}`;
export const getNotFound = () => `/${AppRoutes.NOT_FOUND}`;
