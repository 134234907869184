import { Col, Form, Input, Row, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { DatePicker } from '@shared/ui/DatePicker';
import { l10n } from '@shared/l10n';
import { Select } from '@shared/ui/Select';

import { IConfigMainCarInfoProps } from '../types';
import { useCarMainInformation } from '../hooks/useCarMainInformation';

export function CarMainInformation({
    fullName,
    color,
    govNumber,
    madeYear,
    requiredDriveLicence,
}: IConfigMainCarInfoProps) {
    const { categoryList, isLoading } = useCarMainInformation();
    const { mainInformation } = l10n.cars.form.addNewCar;

    return (
        <>
            <Typography.Title level={5}>{mainInformation.title}</Typography.Title>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="fullName"
                        label={mainInformation.fullName}
                        required
                        rules={fullName?.rules}
                        {...fullName?.item}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder={mainInformation.fullName}
                            {...fullName?.element}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="color"
                        label={mainInformation.color}
                        required
                        rules={color?.rules}
                        {...color?.item}
                    >
                        <Input prefix={<UserOutlined />} placeholder={mainInformation.color} {...color?.element} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="govNumber"
                        label={mainInformation.govNumber}
                        required
                        rules={govNumber?.rules}
                        {...govNumber?.item}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder={mainInformation.govNumber}
                            {...govNumber?.element}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="madeYear"
                        label={mainInformation.madeYear}
                        required
                        rules={madeYear?.rules}
                        {...madeYear?.item}
                    >
                        <DatePicker
                            picker="year"
                            placeholder={mainInformation.madeYear}
                            style={{ width: '100%' }}
                            {...madeYear?.element}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="requiredDriveLicence"
                        label={mainInformation.requiredDriveLicence}
                        required
                        rules={requiredDriveLicence?.rules}
                        {...requiredDriveLicence?.item}
                    >
                        <Select
                            placeholder={mainInformation.requiredDriveLicence}
                            options={categoryList}
                            loading={isLoading}
                            {...requiredDriveLicence?.element}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}
