import { useNavigate } from 'react-router-dom';
import { Button } from '@shared/ui/Button';
import { getCarCreate } from '@shared/config';
import { l10n } from '@shared/l10n';
import { CarOutlined } from '@ant-design/icons';

export function CreateCar() {
    const navigator = useNavigate();
    return (
        <Button onClick={() => navigator(getCarCreate())}>
            <CarOutlined />
            {l10n.common.navbar.createCar}
        </Button>
    );
}
