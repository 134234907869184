import { match } from 'ts-pattern';

import { EStepType } from '../../config/steps';
import { useFormData } from '../../hooks/useFormData';

import { SelectCarContent } from './SelectCarContent';
import { SubmitRentContent } from './SubmitRentContent';
import { SelectUserContent } from './SelectUserContent';

export function FormContent() {
    const { currentStep } = useFormData();

    // @ts-ignore
    return match(currentStep)
        .with(EStepType.SelectCar, () => <SelectCarContent />)
        .with(EStepType.SelectUser, () => <SelectUserContent />)
        .otherwise(() => <SubmitRentContent />);
}
