import { createEvent, restore, sample } from 'effector';
import { createGate } from 'effector-react';
import { UsersApi } from '@shared/api/users/usersApi';
import { createEffectApi } from '@shared/effectorHelpers';
import { MoreDetailsUserModel } from '@features/userActions';

/** Gates */
export const ClientsConfigurationContentGate = createGate('ClientsConfigurationContentGate');

/** Events */
const dataReset = createEvent();

/** Fabrics */
export const usersApi = createEffectApi({ effectFn: UsersApi.getAllUsers, resetEvent: dataReset, defaultState: [] });

/** Stores */
export const $users = restore(usersApi.fetchDataFx.doneData, []).reset(dataReset);
export const $isLoading = usersApi.$isLoading;

/** Derived stores */
export const $selectedUser = MoreDetailsUserModel.$user;

/** Logic */

// --> Получаем список пользователей с сервера
sample({
    clock: ClientsConfigurationContentGate.open,
    target: usersApi.dataFetched,
});

sample({
    clock: ClientsConfigurationContentGate.close,
    target: [dataReset, MoreDetailsUserModel.dataReset],
});
