import { ReactNode } from 'react';
import { Flex, Typography } from 'antd';
import { Warning } from '@shared/icons';

interface IWaningAlertProps {
    title?: ReactNode;
    message?: ReactNode;
}

export function WarningAlert({ title, message }: IWaningAlertProps) {
    return (
        <Flex gap="10px">
            <Warning />
            <Flex vertical>
                <Typography.Text strong>{title}</Typography.Text>
                <Typography.Text>{message}</Typography.Text>
            </Flex>
        </Flex>
    );
}
