import { PageLayout } from '@shared/ui/PageLayout';
import { CarConfigurationContent } from '@widgets/CarConfigurationContent';
import { NavigationBar } from '@shared/ui/NavigationBar';
import { CreateCar } from '@features/navigation/CreateCar';
import { CreateUser } from '@features/navigation/CreateUser';

import { useCarConfigurationPage } from '../hooks/useCarConfigurationPage';

export function CarConfigurationPage() {
    useCarConfigurationPage();

    return (
        <PageLayout>
            <NavigationBar>
                <CreateCar />
                <CreateUser />
            </NavigationBar>
            <CarConfigurationContent />
        </PageLayout>
    );
}
