import { Col, Form, Input, Row, Typography } from 'antd';
import { l10n } from '@shared/l10n';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { TreeSelect } from '@shared/ui/TreeSelect';

import { IConfigPassportInfoProps } from '../types';

export function PassportInformation({
    passportSeries,
    passportNumber,
    country,
    region,
    city,
    street,
    houseNumber,
    flatNumber,
}: IConfigPassportInfoProps) {
    const passportTitle = l10n.clients.form.addNewUser.passportInformation;

    return (
        <>
            <Typography.Title level={5}>{passportTitle.title}</Typography.Title>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="passportSeries"
                        label={passportTitle.passportSeries}
                        required
                        rules={passportSeries?.rules}
                        {...passportSeries?.item}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder={passportTitle.passportSeries}
                            {...passportSeries?.element}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="passportNumber"
                        label={passportTitle.passportNumber}
                        required
                        rules={passportNumber?.rules}
                        {...passportNumber?.item}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder={passportTitle.passportNumber}
                            {...passportNumber?.element}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Typography.Title level={5}>{passportTitle.homeAddress.title}</Typography.Title>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item
                        name="country"
                        label={passportTitle.homeAddress.country}
                        required
                        rules={country?.rules}
                        {...country?.item}
                    >
                        <TreeSelect
                            treeDataSimpleMode
                            placeholder={passportTitle.homeAddress.country}
                            {...country?.element}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="region"
                        label={passportTitle.homeAddress.region}
                        required
                        rules={region?.rules}
                        {...region?.item}
                    >
                        <TreeSelect
                            treeDataSimpleMode
                            placeholder={passportTitle.homeAddress.region}
                            {...region?.element}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="city"
                        label={passportTitle.homeAddress.city}
                        required
                        rules={city?.rules}
                        {...city?.item}
                    >
                        <TreeSelect
                            treeDataSimpleMode
                            placeholder={passportTitle.homeAddress.city}
                            {...city?.element}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="street"
                        label={passportTitle.homeAddress.street}
                        required
                        rules={street?.rules}
                        {...street?.item}
                    >
                        <Input
                            prefix={<HomeOutlined />}
                            placeholder={passportTitle.homeAddress.street}
                            {...street?.element}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="houseNumber"
                        label={passportTitle.homeAddress.houseNumber}
                        required
                        rules={houseNumber?.rules}
                        {...houseNumber?.item}
                    >
                        <Input
                            prefix={<HomeOutlined />}
                            placeholder={passportTitle.homeAddress.houseNumber}
                            {...houseNumber?.element}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="flatNumber"
                        label={passportTitle.homeAddress.flatNumber}
                        required
                        rules={flatNumber?.rules}
                        {...flatNumber?.item}
                    >
                        <Input
                            prefix={<HomeOutlined />}
                            placeholder={passportTitle.homeAddress.flatNumber}
                            {...flatNumber?.element}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}
