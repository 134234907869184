import { useGate, useUnit } from 'effector-react';

import { $isLoading, $selectedUser, $users, ClientsConfigurationContentGate } from '../model/clients';

export function useClientsConfigurationContent() {
    useGate(ClientsConfigurationContentGate);

    const model = useUnit({
        users: $users,
        isLoading: $isLoading,
        selectedUser: $selectedUser,
    });

    return model;
}
