export const clients = {
    actions: {
        selectUser: 'Select',
        moreDetails: 'More details',
    },
    driveLicence: {
        title: 'Drive licence',
        details: {
            series: 'Series',
            inspection: 'GIBDD',
        },
        status: {
            active: 'Active until {0}',
            expired: 'Expired {0}',
        },
    },
    passport: {
        title: 'Passport details',
        series: 'Series',
        city: 'city',
        street: 'street',
        house: 'house',
        flat: 'flat',
    },
    searchClients: {
        placeholder: 'Enter surname or drive licence number',
        searchBtn: 'Search',
        searchEmpty: 'Clients not found',
    },
    form: {
        addNewUser: {
            title: 'Add new client',
            actions: {
                createUser: 'Add',
                cancel: 'Cancel',
            },
            mainInformation: {
                surname: 'Surname',
                name: 'Name',
                patronymic: 'Patronymic',
                birthday: 'Birthday',
            },
            passportInformation: {
                title: 'Passport information',
                passportSeries: 'Series',
                passportNumber: 'Number',
                homeAddress: {
                    title: 'Place of residence',
                    country: 'Country',
                    region: 'Region',
                    city: 'City',
                    street: 'Street',
                    houseNumber: 'House',
                    flatNumber: 'Flat',
                },
            },
            driveLicenceInformation: {
                title: 'Drive licence',
                seriesDrive: 'Series',
                numberDrive: 'Number',
                dateIssued: 'Issued',
                dateExpired: 'Expired',
                inspection: 'Institution',
                validPeriod: 'Period of validity',
                openedCategory: 'Opened categories',
            },
            additionalInformation: {
                title: 'Additional information',
            },
        },
    },
};
