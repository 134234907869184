import { createGate } from 'effector-react';
import { createEvent, restore, sample } from 'effector';
import { createEffectApi } from '@shared/effectorHelpers';
import { CarFilterPanelModel } from '@entities/CarFilterPanel';
import { CarApi } from '@shared/api/cars/carApi';

/** Gates */
export const CarsConfigurationContentGate = createGate('CarsConfigurationContentGate');

/** Events */
const dataReset = createEvent();

/** Fabrics */
export const filtersApi = createEffectApi({
    effectFn: CarApi.getCarFilters,
    resetEvent: dataReset,
    defaultState: null,
});

export const carApi = createEffectApi({
    effectFn: CarApi.getCarList,
    resetEvent: dataReset,
    defaultState: [],
});

/** Derived store */
export const $filterIsLoading = filtersApi.$isLoading;
export const $cars = restore(carApi.fetchDataFx.doneData, []).reset(dataReset);

/** Logic */
// --> Запрашиваем с сервера фильтры и список автомобилей
sample({
    clock: CarsConfigurationContentGate.open,
    target: [filtersApi.dataFetched, carApi.dataFetched],
});

// --> Передаём список фильтров для автомобиля
sample({
    clock: filtersApi.$data,
    filter: Boolean,
    target: CarFilterPanelModel.filtersChanged,
});
