import { IStepProps } from '@shared/ui/Steps/ui/Steps';
import { l10n } from '@shared/l10n';

const { rentContent: stepsTitle } = l10n.common;

export enum EStepType {
    SelectCar,
    SelectUser,
    Confirmation,
}

interface IStepDescriptionChangeProps {
    id: EStepType;
    description: string;
    steps: IStepProps[];
}

export const DEFAULT_STEPS_RENT: IStepProps[] = [
    {
        id: EStepType.SelectCar,
        title: stepsTitle.selectCar,
        description: '',
    },
    {
        id: EStepType.SelectUser,
        title: stepsTitle.selectUser,
        description: '',
    },
    {
        id: EStepType.Confirmation,
        title: stepsTitle.submitRent,
        description: '',
    },
];

/**
 * Утилита для подстановки нового описания для шага
 * */
export function stepDescriptionChange({ steps, description, id }: IStepDescriptionChangeProps) {
    return steps.map((step) => {
        const currentStep = structuredClone(step);

        if (currentStep.id === id) {
            currentStep.description = description;
        }

        return currentStep;
    });
}
