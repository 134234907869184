import { getSymbolsByCount } from '@shared/l10n/lib';

export const common = {
    pages: {
        clients: 'Клиенты',
        carpark: 'Автопарк',
        rent: 'Прокат',
        monitoring: 'Мониторинг',
        settings: 'Настройки',
    },
    rentContent: {
        title: 'Выдача автомобиля в прокат',
        carTitle: 'Автомобиль',
        clientTitle: 'Клиент',
        selectCar: 'Выбор автомобиля',
        selectUser: 'Выбор пользователя',
        submitRent: 'Подтверждение',
        actions: {
            next: 'Далее',
            prev: 'Назад',
            giveToRent: 'Выдать в прокат',
        },
        warningContent: {
            title: 'Внимание',
            message: 'Перед выдачей автомобиля в аренду необходимо удостовериться в корректности введённых данных',
            submitMessage: 'Нажимая кнопку «Выдать в прокат» вы подтверждаете корректность структуры заказа',
        },
    },
    errors: {
        nothingFound: {
            title: 'Ничего не найдено!',
            description: 'Пожалуйста, попробуйте изменить условия запроса',
        },
    },
    validationMessage: {
        notEmpty: 'Поле должно быть заполнено',
        countSymbol: (count: number) => {
            return `Поле должно содержать ${count} ${getSymbolsByCount(count)}`;
        },
        availableCountSymbol: (count: number) => {
            return `Поле должно содержать не более, чем ${count} ${getSymbolsByCount(count)}`;
        },
        onlyNumber: 'Допустимы только цифры',
        notNegative: 'Значение не должно быть меньше нуля',
    },
    navbar: {
        createUser: 'Добавить пользователя',
        createCar: 'Добавить автомобиль',
    },
};
