import { Col, Form, Input, Row, Typography } from 'antd';
import { l10n } from '@shared/l10n';
import { Radio } from '@shared/ui/Radio';
import { ECarDrive, EMotorType, ERoleSide, ETransmission } from '@shared/api/cars/interfaces';
import { UserOutlined } from '@ant-design/icons';

import { IConfigTechnicCarInfoProps } from '../types';

export function CarTechnicalSpecifications({
    motorType,
    transmissionType,
    roleSide,
    carDrive,
    enginePower,
    mileage,
    additionalInfoCar,
}: IConfigTechnicCarInfoProps) {
    const { technicalSpecification } = l10n.cars.form.addNewCar;

    return (
        <>
            <Typography.Title level={5}>{technicalSpecification.title}</Typography.Title>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="motorType"
                        label={technicalSpecification.motor.title}
                        initialValue={EMotorType.Petrol}
                        rules={motorType?.rules}
                        {...motorType?.item}
                    >
                        <Radio.Group name="motorType" {...motorType?.element}>
                            <Radio value={EMotorType.Petrol}>{technicalSpecification.motor.motorType.Petrol}</Radio>
                            <Radio value={EMotorType.Diesel}>{technicalSpecification.motor.motorType.Diesel}</Radio>
                            <Radio value={EMotorType.Gas}>{technicalSpecification.motor.motorType.Gas}</Radio>
                            <Radio value={EMotorType.Electric}>{technicalSpecification.motor.motorType.Electric}</Radio>
                            <Radio value={EMotorType.Hybrid}>{technicalSpecification.motor.motorType.Hybrid}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="transmissionType"
                        label={technicalSpecification.transmission.title}
                        initialValue={ETransmission.Automatic}
                        rules={transmissionType?.rules}
                        {...transmissionType?.item}
                    >
                        <Radio.Group name="transmissionType" {...transmissionType?.element}>
                            <Radio value={ETransmission.Automatic}>
                                {technicalSpecification.transmission.transmissionType.Automatic}
                            </Radio>
                            <Radio value={ETransmission.Mechanic}>
                                {technicalSpecification.transmission.transmissionType.Mechanic}
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="roleSide"
                        label={technicalSpecification.wheel.title}
                        initialValue={ERoleSide.Left}
                        rules={roleSide?.rules}
                        {...roleSide?.item}
                    >
                        <Radio.Group name="roleSide" {...roleSide?.element}>
                            <Radio value={ERoleSide.Left}>{technicalSpecification.wheel.roleSide.Left}</Radio>
                            <Radio value={ERoleSide.Right}>{technicalSpecification.wheel.roleSide.Right}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="carDrive"
                        label={technicalSpecification.transmission.carDriveTitle}
                        initialValue={ECarDrive.Back}
                        rules={carDrive?.rules}
                        {...carDrive?.item}
                    >
                        <Radio.Group name="carDrive" {...carDrive?.element}>
                            <Radio value={ECarDrive.Back}>
                                {technicalSpecification.transmission.carDriveType.Back}
                            </Radio>
                            <Radio value={ECarDrive.Front}>
                                {technicalSpecification.transmission.carDriveType.Front}
                            </Radio>
                            <Radio value={ECarDrive.Full}>
                                {technicalSpecification.transmission.carDriveType.Full}
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="enginePower"
                        label={technicalSpecification.enginePower}
                        rules={enginePower?.rules}
                        {...enginePower?.item}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder={technicalSpecification.enginePower}
                            {...enginePower?.element}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="mileage"
                        label={technicalSpecification.mileage}
                        rules={mileage?.rules}
                        {...mileage?.item}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder={technicalSpecification.mileage}
                            {...mileage?.element}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name="additionalInfoCar"
                label={technicalSpecification.additionalInfo}
                rules={additionalInfoCar?.rules}
                {...additionalInfoCar?.item}
            >
                <Input.TextArea
                    showCount
                    placeholder={technicalSpecification.additionalInfo}
                    maxLength={300}
                    style={{ height: '100px', resize: 'none' }}
                    {...additionalInfoCar?.element}
                />
            </Form.Item>
        </>
    );
}
