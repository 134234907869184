import { Card } from '@shared/ui/Card';
import { l10n } from '@shared/l10n';
import { Flex } from 'antd';
import { Home, Passport } from '@shared/icons';
import { IPassport } from '@shared/api/users/interfaces';
import { StyledTitle, UnderlineContainer } from '@shared/styles';

export function PassportDetails({ number, series, registrationAddress }: IPassport) {
    const { city, street, house, flat } = l10n.clients.passport;
    const homeAddress = `${registrationAddress.state}, ${registrationAddress.region},
                                ${city} ${registrationAddress.city}, ${street} ${registrationAddress.street}, 
                                ${house} ${registrationAddress.house}, ${flat} ${registrationAddress.flat}`;
    return (
        <Card title={<StyledTitle>{l10n.clients.passport.title}</StyledTitle>}>
            <Flex gap="middle" vertical>
                <Flex gap="middle">
                    <Passport />
                    <Flex gap="5px">
                        {l10n.clients.passport.series} <UnderlineContainer>{series}</UnderlineContainer> №{' '}
                        <UnderlineContainer>{number}</UnderlineContainer>
                    </Flex>
                </Flex>
                <Flex gap="middle" align="start">
                    <Home />
                    {homeAddress}
                </Flex>
            </Flex>
        </Card>
    );
}
