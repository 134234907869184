import { IDictionaryResponse } from '@shared/api/dictionary/interfaces';

export interface ICategory {
    value: string;
    label: string;
}

/**
 * Утилита для маппинга категорий прав пользователя
 * */
export function mapCategoriesDictionary(dictionaryItems: IDictionaryResponse[]): ICategory[] {
    return dictionaryItems.map(({ code }) => {
        return { label: code, value: code };
    });
}
