function getBackendErrorMessage(result: object) {
    if ('message' in result) {
        return result.message as string;
    }

    if ('error' in result) {
        return result.error as string;
    }

    return JSON.stringify(result);
}

export async function httpClient<Response>(
    config: RequestInfo,
    init: RequestInit = {},
    abortController?: AbortController
): Promise<Response> {
    try {
        const result = await fetch(config, {
            headers: { 'Content-Type': 'application/json' },
            signal: abortController?.signal,
            ...init,
        });

        if (result.ok) {
            return await result.json();
        }

        throw new Error(getBackendErrorMessage(result));
    } catch (error) {
        throw new Error('Fetching error: ', error as Error);
    }
}
