import { createGate } from 'effector-react';
import { createStore, sample } from 'effector';
import { createEffectApi } from '@shared/effectorHelpers';
import { DictionaryApi } from '@shared/api/dictionary/dictionaryApi';
import { once } from 'patronum';

import { ICategory, mapCategoriesDictionary } from '../utils';

/** Gates */
export const DriveLicenceGate = createGate('DriveLicenceGate');

/** Fabrics */
export const driveCategoriesApi = createEffectApi({
    effectFn: DictionaryApi.getDriveCategoryDictionary,
    defaultState: [],
});

/** Stores */
export const $driveCategories = createStore<ICategory[]>([]);

/** Derived stores */
export const $categoryIsLoading = driveCategoriesApi.$isLoading;

/** Logic */
sample({
    clock: once(DriveLicenceGate.open),
    target: driveCategoriesApi.dataFetched,
});

sample({
    clock: driveCategoriesApi.fetchDataFx.doneData,
    fn: mapCategoriesDictionary,
    target: $driveCategories,
});
