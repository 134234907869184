import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd';
import { ButtonType } from 'antd/es/button';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { CSSProperties, ReactNode } from 'react';

/**
 * Модель пропсов компонента кнопки.
 *
 * @property {ReactNode} [children] - Контент кнопки.
 * @property {ButtonType} [type] - Тип кнопки.
 * @property {ReactNode} [icon] - Иконка кнопки.
 * @property {SizeType} [size] - Размер кнопки.
 * @property {boolean} [disabled] - Состояние кнопки.
 * @property {boolean} [loading] - Состояние загрузки.
 * @property {CSSProperties} [style] - Стили кнопки.
 * @property {Function} [onClick] - Обработчик нажатия.
 * */
export interface ButtonProps extends AntdButtonProps {
    children?: ReactNode;
    type?: ButtonType;
    icon?: ReactNode;
    size?: SizeType;
    disabled?: boolean;
    loading?: boolean;
    className?: string;
    title?: string;
    style?: CSSProperties;
    onClick?: AntdButtonProps['onClick'];
}

/**
 * Простой компонент кнопки.
 * */
export function Button(props: ButtonProps) {
    return <AntdButton type="primary" {...props} />;
}

Button.Group = AntdButton.Group;
