import { createEffect, createEvent, createStore, Event, sample } from 'effector';

interface ICreateEffectApiProps<Result, Args = void> {
    effectFn: (arg: Args) => Promise<Result>;
    defaultState: Result | null;
    resetEvent?: Event<void>;
}

/**
 * Хелпер для работы с асинхронными эффектами
 *
 * @property {() => Promise<Result>} effectFn - Функция обращения к API
 * @property {Result | null} defaultState - Дефолтное состояние стора
 * @property {Event<void>} [resetEvent] - Событие для сброса состояния
 *
 * */
export function createEffectApi<Result, Args = void>({
    effectFn,
    defaultState,
    resetEvent,
}: ICreateEffectApiProps<Result, Args>) {
    const defaultReset = createEvent();
    const fetchDataFx = createEffect(effectFn);
    const dataFetched = createEvent<Args>();

    const $data = createStore(defaultState)
        .on(fetchDataFx.doneData, (_, payload) => {
            return payload;
        })
        .reset(resetEvent ?? defaultReset);
    const $isLoading = fetchDataFx.pending;

    sample({
        clock: dataFetched,
        target: fetchDataFx,
    });

    return {
        $data,
        $isLoading,
        dataFetched,
        fetchDataFx,
        defaultReset,
        inFlight: fetchDataFx.inFlight,
    };
}
