import { Flex } from 'antd';
import { CarFilterPanel } from '@entities/CarFilterPanel';
import { l10n } from '@shared/l10n';
import { List } from '@shared/ui/List';
import { CarCard } from '@entities/CarCard';
import { Search } from '@shared/ui/Search';
import { MoreInformation } from '@features/carActions/MoreInformation';
import { GiveToRent } from '@features/carActions/GiveToRent';

import { useCarConfigurationContent } from '../hooks/useCarConfigurationContent';
import { CarContentContainer } from '../styled';

import { CarStatus } from './CarStatus';

export function CarConfigurationContent() {
    const { filterIsLoading, cars } = useCarConfigurationContent();
    const { filters } = l10n.cars;

    return (
        <CarContentContainer>
            <Flex gap="middle" justify="center">
                <CarFilterPanel title={filters.title} isLoading={filterIsLoading} />
                <Flex vertical gap="middle" style={{ maxWidth: '1100px', width: '100%' }}>
                    <Search placeholder={filters.searchPlaceholder} enterButton={filters.searchBtn} size="middle" />
                    <Flex vertical gap="middle">
                        <List
                            dataSource={cars}
                            renderItem={(car) => (
                                <List.Item>
                                    <CarCard
                                        car={car}
                                        actions={
                                            <Flex style={{ padding: '10px' }} gap="10px">
                                                <CarStatus car={car} />
                                                <MoreInformation />
                                                <GiveToRent />
                                            </Flex>
                                        }
                                    />
                                </List.Item>
                            )}
                            loading={filterIsLoading}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </CarContentContainer>
    );
}
