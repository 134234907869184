import { Steps as AntdSteps, StepProps, StepsProps } from 'antd';

export interface IStepProps extends StepProps {
    id?: string | number;
    title?: string;
    description?: string;
}

export interface ISteps extends StepsProps {
    size?: 'default' | 'small';
    current?: number;
    items?: IStepProps[];
    classNames?: string;
}

export function Steps(props: ISteps) {
    return <AntdSteps size="small" {...props} />;
}
