import Icon from '@ant-design/icons';

import { ReactComponent as HomeSvg } from '../assets/homeIcon.svg';

interface IHome {
    size?: number;
}
export function Home({ size = 20 }: IHome) {
    return <Icon alt="Home Icon" component={HomeSvg} style={{ fontSize: size }} />;
}
