import { l10n } from '@shared/l10n';
import { CheckboxGroup } from '@shared/ui/CheckboxGroup';
import { CSSProperties } from 'react';
import { ICarFilter } from '@shared/api/cars/interfaces';
import { Typography } from 'antd';

interface IFilterNames {
    [key: string]: string;
}

const filterNames: IFilterNames = {
    motorType: l10n.cars.filters.motorType,
    roleSide: l10n.cars.filters.roleSide,
    transmission: l10n.cars.filters.transmission,
    carDrive: l10n.cars.filters.carDrive,
};

const styleGroup: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
};

/**
 * Утилита для подготовки фильтров, полученных с сервера к отображению
 * */
export function mapCarFilters(filters: ICarFilter) {
    return Object.keys(filters).map((filterType) => {
        const checkboxOptions = filters[filterType as keyof ICarFilter].map((filter) => filter.name);

        return {
            key: filterType,
            label: <Typography.Text strong>{filterNames[filterType]}</Typography.Text>,
            children: <CheckboxGroup options={checkboxOptions} style={styleGroup} />,
        };
    });
}
