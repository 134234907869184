import { greenBtn } from '@shared/styles';
import { l10n } from '@shared/l10n';
import { Button } from '@shared/ui/Button';
import { IUser } from '@shared/api/users/interfaces';

import { useMoreDetails } from '../hooks/useMoreDetails';

interface ISelectUserProps {
    user: IUser;
}

export function MoreDetails({ user }: ISelectUserProps) {
    const { userSelected } = useMoreDetails();

    const handleUserSelect = () => {
        userSelected(user);
    };

    return (
        <Button type="primary" style={greenBtn} onClick={handleUserSelect}>
            {l10n.clients.actions.moreDetails}
        </Button>
    );
}
