import { useGate, useUnit } from 'effector-react';

import { $currentUser, $isLoading, $users, UsersConfigurationContentGate } from '../model/users';

export function useUserContent() {
    useGate(UsersConfigurationContentGate);

    const model = useUnit({
        users: $users,
        isLoading: $isLoading,
        currentUser: $currentUser,
    });

    const nextDisabled = !model.currentUser;

    return { ...model, nextDisabled };
}
