import { Button } from '@shared/ui/Button';
import { greenBtn } from '@shared/styles';
import { l10n } from '@shared/l10n';
import { ICar } from '@shared/api/cars/interfaces';
import { useSelectCar } from '@features/carActions/SelectCar/hooks/useSelectCar';

interface ISelectCarProps {
    car: ICar;
}

export function SelectCar({ car }: ISelectCarProps) {
    const { handleSelectCar } = useSelectCar();
    return (
        <Button type="primary" style={greenBtn} onClick={() => handleSelectCar(car)}>
            {l10n.cars.actions.select}
        </Button>
    );
}
