import { Button, Flex, Form, Typography } from 'antd';
import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import { l10n } from '@shared/l10n';
import { FormContainer, redBtn } from '@shared/styles';
import {
    AdditionalInformation,
    DriveLicenceInformation,
    MainUserInformation,
    PassportInformation,
} from '@entities/form/user';

import { driveLicenceRules, mainInfoRules, passportRules } from '../config';

export function NewUserConfigurationContent() {
    const { addNewUser } = l10n.clients.form;

    const onFinish = (values: any) => {
        console.log(values);
    };

    const onFinishFailed = (values: any) => {
        console.log(values);
    };

    return (
        <FormContainer
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Typography.Title level={4} style={{ textAlign: 'center' }}>
                {addNewUser.title}
            </Typography.Title>
            <MainUserInformation {...mainInfoRules} />
            <PassportInformation {...passportRules} />
            <DriveLicenceInformation {...driveLicenceRules} />
            <AdditionalInformation />
            <Form.Item>
                <Flex justify="flex-end" gap="middle" style={{ marginTop: '30px' }}>
                    <Button type="primary" htmlType="submit" style={redBtn}>
                        {addNewUser.actions.cancel}
                        <DeleteOutlined />
                    </Button>
                    <Button type="primary" htmlType="submit">
                        {addNewUser.actions.createUser}
                        <UserAddOutlined />
                    </Button>
                </Flex>
            </Form.Item>
        </FormContainer>
    );
}
