import { useCallback } from 'react';
import { useUnit } from 'effector-react';
import { ICar } from '@shared/api/cars/interfaces';

import { $currentCar, carSelected } from '../model';

export function useSelectCar() {
    const model = useUnit({
        currentCar: $currentCar,
    });

    const handleSelectCar = useCallback((car: ICar) => {
        carSelected(car);
    }, []);

    return { ...model, carSelected, handleSelectCar };
}
