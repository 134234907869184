import { CSSProperties, ReactNode } from 'react';
import { Space } from 'antd';

import style from './NavigationBar.module.scss';

interface INavigationBar {
    className?: CSSProperties;
    children?: ReactNode;
}

export function NavigationBar({ children, className }: INavigationBar) {
    return <Space className={`${style.navbar} ${className}`}>{children}</Space>;
}
